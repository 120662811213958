import { useContext } from 'react'
import { Box, IconButton, Button, useTheme } from '@mui/material'
import InputBase from '@mui/material/InputBase'
import LightModeOutlinedIcon from '@mui/icons-material/LightModeOutlined'
import DarkModeOutlinedIcon from '@mui/icons-material/DarkModeOutlined'
import SearchIcon from '@mui/icons-material/Search'
import ExitToAppIcon from '@mui/icons-material/ExitToApp' // Import the ExitToAppIcon

import { signout } from '../../auth/index'
import { ColorModeContext, tokens } from '../../theme'

import { useNavigate } from 'react-router-dom'

//=================================== Topbar  compenent =======================

const Topbar = () => {
  const theme = useTheme()
  const colors = tokens(theme.palette.mode)
  const colorMode = useContext(ColorModeContext)

  const navigate = useNavigate()

  const handleSignout = () => {
    signout(navigate)
  }

  return (
    <Box display='flex' justifyContent='space-between' p={2}>
      {/* SEARCH BAR */}
      <Box
        display='flex'
        backgroundColor={colors.primary[400]}
        borderRadius='3px'
      >
        <InputBase sx={{ ml: 2, flex: 1 }} placeholder='Search' />
        <IconButton type='button' sx={{ p: 1 }}>
          <SearchIcon />
        </IconButton>
      </Box>

      {/* ICONS */}
      <Box display='flex'>
        <IconButton onClick={colorMode.toggleColorMode}>
          {theme.palette.mode === 'dark' ? (
            <DarkModeOutlinedIcon />
          ) : (
            <LightModeOutlinedIcon />
          )}
        </IconButton>

        {/* SIGNOUT BUTTON */}
        <Button
          onClick={handleSignout}
          color='inherit'
          startIcon={<ExitToAppIcon />}
        >
          Sign Out
        </Button>
      </Box>
    </Box>
  )
}

export default Topbar
