import { API } from '../config'
import { jwtDecode } from 'jwt-decode'

export const signin = (user) => {
  return fetch(`${API}/auth/admin-login`, {
    method: 'POST',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(user),
  })
    .then((response) => {
      return response.json()
    })
    .catch((err) => {
      console.log(err)
    })
}

export const authenticate = (data, next) => {
  if (typeof window !== 'undefined') {
    localStorage.setItem('jwt', JSON.stringify(data))
    next()
  }
}

export const signout = (navigate) => {
  if (typeof window !== 'undefined') {
    localStorage.removeItem('jwt')
  }
  navigate('./login')
}

export const isAuthenticated = () => {
  if (typeof window === 'undefined') {
    return false
  }
  const token = localStorage.getItem('jwt')
  if (token) {
    const decodedToken = jwtDecode(token)

    return {
      username: decodedToken.username,
      token,
      decodedToken,
    }
  } else {
    return false
  }
}
