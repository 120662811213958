import React, { useState, useEffect } from 'react'

import {
  Box,
  Button,
  Alert,
  Snackbar,
  useMediaQuery,
  MenuItem,
  Select,
  FormControl,
  InputLabel,
} from '@mui/material'
import { getCourses, assignCourse } from '../../actions/apiCourseManagement' // Import the necessary functions
import { getTeachers } from '../../actions/apiTeachersManagement'

import Header from '../../components/Header'

const AssignCourse = () => {
  const isNonMobile = useMediaQuery('(min-width:600px)')
  const [values, setValues] = useState({
    course: '',
    teacher: '',
    success: false,
  })
  const [courses, setCourses] = useState([])
  const [teachers, setTeachers] = useState([])
  const { course, teacher } = values

  const [open, setOpen] = useState(false)
  const [openConflict, setOpenConflict] = useState(false)

  const handleChange = (name) => (event) => {
    const value = event.target.value
    setValues({ ...values, [name]: value })
  }

  const handleFormSubmit = async (event) => {
    event.preventDefault()

    try {
      const data = await assignCourse(course, teacher)

      if (data.error) {
        setValues({
          ...values,
          success: false,
        })

        if (data.status === 409) {
          handleClickConflict()
        }
      } else {
        setValues({
          ...values,
          success: true,
        })
        handleOpenSuccessToaster()
      }
    } catch (error) {
      console.log(error)
    }
  }

  const handleCloseSuccessToaster = (event, reason) => {
    setOpen(false)
  }

  const handleOpenSuccessToaster = () => {
    setOpen(true)
    setValues({
      course: '',
      teacher: '',
      success: true,
    })
  }

  const handleCloseConflict = (event, reason) => {
    setOpenConflict(false)
  }

  const handleClickConflict = () => {
    setOpenConflict(true)
  }

  useEffect(() => {
    // Fetch courses and teachers when the component mounts
    const fetchData = async () => {
      try {
        const coursesData = await getCourses()
        const teachersData = await getTeachers()
        setCourses(coursesData)
        setTeachers(teachersData)
      } catch (error) {
        console.log(error)
      }
    }

    fetchData()
  }, [])

  return (
    <Box m='20px'>
      <Snackbar
        open={open}
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
        autoHideDuration={2000}
        onClose={handleCloseSuccessToaster}
      >
        <Alert variant='filled' severity='success'>
          Course added successfully!
        </Alert>
      </Snackbar>
      <Snackbar
        open={openConflict}
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
        autoHideDuration={2000}
        onClose={handleCloseConflict}
      >
        <Alert variant='filled' severity='error'>
          Conflict: Course already exists.
        </Alert>
      </Snackbar>

      <Header title='Assign a Course to a Teacher' />

      <form onSubmit={handleFormSubmit}>
        <Box
          display='grid'
          gap='30px'
          gridTemplateColumns='repeat(4, minmax(0, 1fr))'
          sx={{
            '& > div': { gridColumn: isNonMobile ? undefined : 'span 4' },
          }}
        >
          <FormControl fullWidth sx={{ gridColumn: 'span 4' }}>
            <InputLabel id='course-label'>Select Course</InputLabel>
            <Select
              labelId='course-label'
              id='course'
              value={course}
              onChange={handleChange('course')}
              required
            >
              {courses.map((course) => (
                <MenuItem key={course.id} value={course.id}>
                  {course.name}
                </MenuItem>
              ))}
            </Select>
          </FormControl>

          <FormControl fullWidth sx={{ gridColumn: 'span 4' }}>
            <InputLabel id='teacher-label'>Select Teacher</InputLabel>
            <Select
              labelId='teacher-label'
              id='teacher'
              value={teacher}
              onChange={handleChange('teacher')}
              required
            >
              {teachers.map((teacher) => (
                <MenuItem key={teacher.id} value={teacher.id}>
                  {teacher.username}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Box>

        <Box display='flex' justifyContent='end' mt='20px'>
          <Button type='submit' color='secondary' variant='contained'>
            Assign Course
          </Button>
        </Box>
      </form>
    </Box>
  )
}

export default AssignCourse
