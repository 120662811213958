import { API } from '../config'
import { isAuthenticated } from '../auth/index'

// Get All Grades
export const getAllGrades = () => {
  const { token } = isAuthenticated()
  const jsonObject = JSON.parse(token)
  const access_token = jsonObject.access_token

  return fetch(`${API}/grades`, {
    method: 'GET',
    headers: {
      Authorization: `Bearer ${access_token}`,
    },
  })
    .then((response) => response.json())
    .catch((err) => console.log(err))
}

// Get All Branches
export const getBranchesByGrade = (gradeCode) => {
  const { token } = isAuthenticated()
  const jsonObject = JSON.parse(token)
  const access_token = jsonObject.access_token

  return fetch(`${API}/branches/grad-branches/${gradeCode}`, {
    method: 'GET',
    headers: {
      Authorization: `Bearer ${access_token}`,
    },
  })
    .then((response) => response.json())
    .catch((err) => console.log(err))
}

// Get All Majors
export const getMajorsByBranch = (branchId) => {
  const { token } = isAuthenticated()
  const jsonObject = JSON.parse(token)
  const access_token = jsonObject.access_token

  return fetch(`${API}/majors/branch-majors/${branchId}`, {
    method: 'GET',
    headers: {
      Authorization: `Bearer ${access_token}`,
    },
  })
    .then((response) => response.json())
    .catch((err) => console.log(err))
}

// Get All Semesters
export const getSemestersByMajor = (majorId) => {
  const { token } = isAuthenticated()
  const jsonObject = JSON.parse(token)
  const access_token = jsonObject.access_token

  return fetch(`${API}/semesters/find-all-by-major/${majorId}`, {
    method: 'GET',
    headers: {
      Authorization: `Bearer ${access_token}`,
    },
  })
    .then((response) => response.json())
    .catch((err) => console.log(err))
}

// Get All Semesters
export const getSemestersByBranch = (branchId) => {
  const { token } = isAuthenticated()
  const jsonObject = JSON.parse(token)
  const access_token = jsonObject.access_token

  return fetch(`${API}/semesters/find-all-by-brach/${branchId}`, {
    method: 'GET',
    headers: {
      Authorization: `Bearer ${access_token}`,
    },
  })
    .then((response) => response.json())
    .catch((err) => console.log(err))
}
