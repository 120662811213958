import React, { useState, useEffect } from 'react'
import { useNavigate } from 'react-router-dom'

import { Box, Typography, useTheme, CircularProgress } from '@mui/material'
import { DataGrid } from '@mui/x-data-grid'
import { tokens } from '../../theme'
import Header from '../../components/Header'

//==============import for logic ===========
import {
  updateStudent,
  getStudents,
  getStudent,
} from '../../actions/apiStudentsManagement'

import Button from '@mui/material/Button'

import CustomToolBar from '../../components/CustomToolBar'

//========================== Students list component ===============================/
const StudentList = () => {
  const theme = useTheme()
  const colors = tokens(theme.palette.mode)
  const navigate = useNavigate()

  const [students, setStudents] = useState([])
  const [loading, setLoading] = useState(false)

  //========================== useEffect ===============================/
  useEffect(() => {
    setLoading(true)

    getStudents()
      .then((data) => {
        if (data.error) {
          console.log(data.error)
        } else {
          setStudents(data)
        }
      })
      .finally(() => {
        setLoading(false)
      })
  }, [])
  // ====================================Function to block a teacher========================================
  const blockStudent = (id) => {
    getStudent(id)
      .then((student) => {
        if (student) {
          //------------ mise a jour  the teacher's isBlocked property to true
          student.isBlocked = true
          // ------------use API function to update the teacher
          return updateStudent(id, { isBlocked: true })
        } else {
          throw new Error(`Teacher with ID ${id} not found.`)
        }
      })
      .then(() => {
        //---------- this to immedtiatly show the changes once the update is done by update the local state to reflect the change immediately
        setStudents((prevStudents) =>
          prevStudents.map((student) =>
            student.id === id ? { ...student, isBlocked: true } : student
          )
        )
      })
      .catch((error) => {
        console.error(error)
      })
  }

  //===================================================== Function to unblock a student=====================
  const unblockStudent = (id) => {
    getStudent(id)
      .then((student) => {
        if (student) {
          // Update the teacher's isBlocked property to false
          student.isBlocked = false
          // Use the API function to update the teacher in the database
          return updateStudent(id, { isBlocked: false })
        } else {
          throw new Error(`Teacher with ID ${id} not found.`)
        }
      })
      .then(() => {
        //---------- this to immedtiatly show the changes once the ublock is done by update the local state to reflect the change immediately
        setStudents((prevStudents) =>
          prevStudents.map((student) =>
            student.id === id ? { ...student, isBlocked: false } : student
          )
        )
      })
      .catch((error) => {
        console.error(error)
      })
  }
  //========================== render block button by chaning from blcok to unblock ===============================/
  const renderBlockButton = (isBlocked, id) => {
    const buttonText = isBlocked ? 'Unblock' : 'Block'
    const buttonColor = isBlocked ? 'grey' : 'error'

    return (
      <Button
        variant='contained'
        color={buttonColor}
        onClick={() => (isBlocked ? unblockStudent(id) : blockStudent(id))}
        style={{ maxWidth: '100%' }}
      >
        <Typography>{buttonText}</Typography>
      </Button>
    )
  }
  //========================== redirect  to profile ===============================/
  const redirectToProfile = (teacherId) => {
    // Redirect to the teacher's profile page
    navigate(`/student/profile/${teacherId}`)
  }

  //=============================== Columns=====================================
  const columns = [
    { field: 'id', headerName: 'ID', flex: 0.2 },
    {
      field: 'username',
      headerName: 'username',
      flex: 0.8,
      cellClassName: 'name-column--cell',
    },

    {
      field: 'email',
      headerName: 'Email',
      flex: 1,
      cellClassName: 'name-column--cell',
    },
    {
      field: 'wilayaCode',
      headerName: 'Wilaya',
      flex: 0.5,
      cellClassName: 'name-column--cell',
    },
    {
      field: 'profile',
      headerName: 'Profile',
      flex: 0.5,
      renderCell: ({ row }) => (
        <Button
          variant='outlined'
          color='secondary'
          onClick={() => redirectToProfile(row.id)}
        >
          <Typography color={colors.grey[100]}>View</Typography>
        </Button>
      ),
    },
    {
      field: 'delete',
      headerName: 'Block Student',
      flex: 0.5,
      renderCell: ({ row }) => renderBlockButton(row.isBlocked, row.id),
    },
  ]

  //========================== return section ===============================/
  return (
    <Box m='20px'>
      <Header title='List of Students' />
      <Box
        m={{ xs: '20px 0 0 0', md: '40px 0 0 0' }}
        height='75vh'
        sx={{
          position: 'relative',
          '& .MuiDataGrid-root': {
            border: 'none',
            fontSize: { xs: 14, md: 16 },
          },
          '& .MuiDataGrid-cell': {
            borderBottom: 'none',
          },
          '& .name-column--cell': {
            color: colors.greenAccent[300],
          },
          '& .MuiDataGrid-columnHeaders': {
            backgroundColor: colors.blueAccent[700],
            borderBottom: 'none',
          },
          '& .MuiDataGrid-virtualScroller': {
            backgroundColor: colors.primary[400],
          },
          '& .MuiDataGrid-footerContainer': {
            borderTop: 'none',
            backgroundColor: colors.blueAccent[700],
          },
          '& .MuiCheckbox-root': {
            color: `${colors.greenAccent[200]} !important`,
          },
          '& .MuiDataGrid-toolbarContainer .MuiButton-text': {
            color: `${colors.grey[100]} !important`,
          },
        }}
      >
        {loading && ( // Show loading indicator while data is being fetched
          <CircularProgress
            style={{
              position: 'absolute',
              top: '50%',
              left: '50%',
              transform: 'translate(-50%, -50%)',
              color: `${colors.grey[100]} !important`,
            }}
            color='secondary'
          />
        )}
        {!loading && ( // Show grid once data is loaded
          <DataGrid
            rows={students}
            columns={columns}
            components={{ Toolbar: CustomToolBar }}
          />
        )}
      </Box>
    </Box>
  )
}

export default StudentList
