import { API } from '../config'
import { isAuthenticated } from '../auth/index'

export const createStudent = (birthDate, gender, wilaya, idToken) => {
  const { token } = isAuthenticated()
  const jsonObject = JSON.parse(token)
  const access_token = jsonObject.access_token

  const requestBody = JSON.stringify({
    birthDate: birthDate,
    geneder: gender,
    wilayacode: wilaya,
    IDToken: idToken,
  })

  return fetch(`${API}/student`, {
    method: 'POST',
    headers: {
      Authorization: `Bearer ${access_token}`,
      'Content-Type': 'application/json',
    },
    body: requestBody,
  })
    .then(async (response) => {
      const data = await response.json()
      if (!response.ok) {
        return { error: true, status: response.status }
      }
      return { data, status: response.status }
    })
    .catch((err) => {
      console.log(err)
      return { error: 'An unexpected error occurred.' }
    })
}

// getAllStudents

export const getStudents = () => {
  const { token } = isAuthenticated()

  const jsonObject = JSON.parse(token)
  const access_token = jsonObject.access_token
  return fetch(`${API}/students`, {
    method: 'GET',
    headers: {
      Authorization: `Bearer ${access_token}`,
    },
  })
    .then((response) => {
      return response.json()
    })
    .catch((err) => {
      console.log(err)
    })
}

//getOneStudent

export const getStudent = (id) => {
  const { token } = isAuthenticated()

  const jsonObject = JSON.parse(token)
  const access_token = jsonObject.access_token
  return fetch(`${API}/students/${id}`, {
    method: 'GET',
    headers: {
      Authorization: `Bearer ${access_token}`,
    },
  })
    .then((response) => {
      return response.json()
    })
    .catch((err) => {
      console.log(err)
    })
}

//update etacher

export const updateStudent = (id, updatedData) => {
  const { token } = isAuthenticated()

  const jsonObject = JSON.parse(token)
  const access_token = jsonObject.access_token
  return fetch(`${API}/students/${id}`, {
    method: 'PATCH', // or 'PATCH' depending on your API
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${access_token}`,
    },
    body: JSON.stringify(updatedData),
  })
    .then((response) => {
      return response.json()
    })
    .catch((err) => {
      console.log(err)
    })
}

//delete TEcheer
export const deleteStudent = (id) => {
  const { token } = isAuthenticated()
  console.log('the toke is :', token)
  const jsonObject = JSON.parse(token)
  const access_token = jsonObject.access_token
  return fetch(`${API}/students/${id}`, {
    method: 'DELETE',
    headers: {
      Authorization: `Bearer ${access_token}`,
    },
  })
    .then((response) => {
      return response.json()
    })
    .catch((err) => {
      console.log(err)
    })
}
