import { API } from '../config'
import { isAuthenticated } from '../auth/index'

export const createCourse = (formData) => {
  const { token } = isAuthenticated()
  const jsonObject = JSON.parse(token)
  const access_token = jsonObject.access_token

  return fetch(`${API}/courses`, {
    method: 'POST',
    headers: {
      Authorization: `Bearer ${access_token}`,
    },
    body: formData,
  })
    .then(async (response) => {
      const data = await response.json()
      if (!response.ok) {
        return { error: true, status: response.status }
      }
      return { data, status: response.status }
    })
    .catch((err) => {
      console.log(err)
      return { error: 'An unexpected error occurred.' }
    })
}

//==================course next step============================
export const createCourseNextStep = (semester, courseId) => {
  const { token } = isAuthenticated()
  const jsonObject = JSON.parse(token)
  const access_token = jsonObject.access_token
  const requestBody = JSON.stringify({
    semesterId: semester,
    courseId: courseId,
  })
  return fetch(`${API}/semester-courses`, {
    method: 'POST',
    headers: {
      Authorization: `Bearer ${access_token}`,

      'Content-Type': 'application/json',
    },
    body: requestBody,
  })
    .then(async (response) => {
      const data = await response.json()
      if (!response.ok) {
        return { error: true, status: response.status }
      }
      return { data, status: response.status }
    })
    .catch((err) => {
      console.log(err)
      return { error: 'An unexpected error occurred.' }
    })
}

// getAllCourses

export const getCourses = () => {
  const { token } = isAuthenticated()

  const jsonObject = JSON.parse(token)
  const access_token = jsonObject.access_token
  return fetch(`${API}/courses`, {
    method: 'GET',
    headers: {
      Authorization: `Bearer ${access_token}`,
    },
  })
    .then((response) => {
      return response.json()
    })
    .catch((err) => {
      console.log(err)
    })
}

// getAllAssignedCourses

export const getAssignedCourses = () => {
  const { token } = isAuthenticated()

  const jsonObject = JSON.parse(token)
  const access_token = jsonObject.access_token
  return fetch(`${API}/instructor-course`, {
    method: 'GET',
    headers: {
      Authorization: `Bearer ${access_token}`,
    },
  })
    .then((response) => {
      return response.json()
    })
    .catch((err) => {
      console.log(err)
    })
}

//getOneCourse

export const getCourse = (id) => {
  const { token } = isAuthenticated()

  const jsonObject = JSON.parse(token)
  const access_token = jsonObject.access_token
  return fetch(`${API}/course/${id}`, {
    method: 'GET',
    headers: {
      Authorization: `Bearer ${access_token}`,
    },
  })
    .then((response) => {
      return response.json()
    })
    .catch((err) => {
      console.log(err)
    })
}

//update etacher

export const updateCourse = (id, updatedData) => {
  const { token } = isAuthenticated()

  const jsonObject = JSON.parse(token)
  const access_token = jsonObject.access_token
  return fetch(`${API}/course/${id}`, {
    method: 'PUT', // or 'PATCH' depending on your API
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${access_token}`,
    },
    body: JSON.stringify(updatedData),
  })
    .then((response) => {
      return response.json()
    })
    .catch((err) => {
      console.log(err)
    })
}

//delete TEcheer
export const deleteCourse = (id) => {
  const { token } = isAuthenticated()

  const jsonObject = JSON.parse(token)
  const access_token = jsonObject.access_token
  return fetch(`${API}/courses/${id}`, {
    method: 'DELETE',
    headers: {
      Authorization: `Bearer ${access_token}`,
    },
  })
    .then((response) => {
      return response.json()
    })
    .catch((err) => {
      console.log(err)
    })
}

//==================Assign course to Teacher========
export const assignCourse = (course, instructor) => {
  const { token } = isAuthenticated()
  const jsonObject = JSON.parse(token)
  const access_token = jsonObject.access_token

  const requestBody = JSON.stringify({
    courseId: course,
    instructorId: instructor,
  })
  return fetch(`${API}/instructor-course`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${access_token}`,
    },
    body: requestBody,
  })
    .then(async (response) => {
      const data = await response.json()
      if (!response.ok) {
        return { error: true, status: response.status }
      }
      return { data, status: response.status }
    })
    .catch((err) => {
      console.log(err)
      return { error: 'An unexpected error occurred.' }
    })
}

//delete Assigned Coursw
export const deleteAssignedCourse = (instructorId, courseId) => {
  const { token } = isAuthenticated()

  const jsonObject = JSON.parse(token)
  const access_token = jsonObject.access_token
  return fetch(`${API}/instructor-course/${instructorId}/${courseId}`, {
    method: 'DELETE',
    headers: {
      Authorization: `Bearer ${access_token}`,
    },
  })
    .then((response) => {
      return response.json()
    })
    .catch((err) => {
      console.log(err)
    })
}
