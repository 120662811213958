import { API } from '../config'
import { isAuthenticated } from '../auth'

//====================Get all semesters=====================//
export const getSemesters = () => {
  const { token } = isAuthenticated()
  const jsonObject = JSON.parse(token)
  const access_token = jsonObject.access_token

  return fetch(`${API}/semesters`, {
    method: 'GET',
    headers: {
      Authorization: `Bearer ${access_token}`,
    },
  })
    .then((response) => response.json())
    .catch((err) => console.log(err))
}

//====================Get The semesters based on branches=====================//
export const getSemestersBranches = (branchId) => {
  const { token } = isAuthenticated()
  const jsonObject = JSON.parse(token)
  const access_token = jsonObject.access_token

  return fetch(`${API}/semesters/find-all-by-branch/${branchId}`, {
    method: 'GET',
    headers: {
      Authorization: `Bearer ${access_token}`,
    },
  })
    .then((response) => response.json())
    .catch((err) => console.log(err))
}

//====================Get The semesters based on majors=====================//
export const getSemestersMajors = (majorId) => {
  const { token } = isAuthenticated()
  const jsonObject = JSON.parse(token)
  const access_token = jsonObject.access_token

  return fetch(`${API}/semesters/find-all-by-major/${majorId}`, {
    method: 'GET',
    headers: {
      Authorization: `Bearer ${access_token}`,
    },
  })
    .then((response) => response.json())
    .catch((err) => console.log(err))
}
//==============================create a semsester ================//
export const createSemester = (number, branchId, majorId) => {
  const { token } = isAuthenticated()
  const jsonObject = JSON.parse(token)
  const access_token = jsonObject.access_token

  const requestBody = JSON.stringify({
    number: number,
    branchId: branchId,
    majorId: majorId,
  })

  return fetch(`${API}/semesters`, {
    method: 'POST',
    headers: {
      Authorization: `Bearer ${access_token}`,
      'Content-Type': 'application/json',
    },
    body: requestBody,
  })
    .then(async (response) => {
      const data = await response.json()
      if (!response.ok) {
        return { error: true, status: response.status }
      }
      return { data, status: response.status }
    })
    .catch((err) => {
      console.log(err)
      return { error: 'An unexpected error occurred.' }
    })
}

//====================Get The semesters based on majors=====================//
export const deleteSemester = (semesterId) => {
  const { token } = isAuthenticated()
  const jsonObject = JSON.parse(token)
  const access_token = jsonObject.access_token

  return fetch(`${API}/semesters/${semesterId}`, {
    method: 'DELETE',
    headers: {
      Authorization: `Bearer ${access_token}`,
    },
  })
    .then((response) => response.json())
    .catch((err) => console.log(err))
}
