import React from 'react'
import { Navigate } from 'react-router-dom'
import { isAuthenticated, signout } from './index'

const AdminRoute = ({ component: Component }) => {
  const user = isAuthenticated()

  if (!user) {
    return (
      <Navigate
        to={{
          pathname: '/login',
        }}
      />
    )
  }

  //====================Check if token is expired
  const currentTime = Date.now() / 1000
  if (
    user.decodedToken &&
    user.decodedToken.exp &&
    user.decodedToken.exp < currentTime
  ) {
    signout(() => {
      window.location.href = '/login'
      console.log(user.token)
    })
    return null
  }

  return <Component />
}

export default AdminRoute
