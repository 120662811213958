import { API } from '../config'
import { isAuthenticated } from '../auth/index'

export const createTeacher = (username, password) => {
  const { token } = isAuthenticated()
  const jsonObject = JSON.parse(token)
  const access_token = jsonObject.access_token

  const requestBody = JSON.stringify({
    username: username,
    password: password,
  })

  return fetch(`${API}/instructor`, {
    method: 'POST',
    headers: {
      Authorization: `Bearer ${access_token}`,
      'Content-Type': 'application/json',
    },
    body: requestBody,
  })
    .then(async (response) => {
      const data = await response.json()
      if (!response.ok) {
        return { error: true, status: response.status }
      }
      return { data, status: response.status }
    })
    .catch((err) => {
      console.log(err)
      return { error: 'An unexpected error occurred.' }
    })
}

// getAllTeachers

export const getTeachers = () => {
  const { token } = isAuthenticated()

  const jsonObject = JSON.parse(token)
  const access_token = jsonObject.access_token
  return fetch(`${API}/instructor`, {
    method: 'GET',
    headers: {
      Authorization: `Bearer ${access_token}`,
    },
  })
    .then((response) => {
      return response.json()
    })
    .catch((err) => {
      console.log(err)
    })
}

//getOneteacher

export const getTeacher = (id) => {
  const { token } = isAuthenticated()

  const jsonObject = JSON.parse(token)
  const access_token = jsonObject.access_token
  return fetch(`${API}/instructor/${id}`, {
    method: 'GET',
    headers: {
      Authorization: `Bearer ${access_token}`,
    },
  })
    .then((response) => {
      return response.json()
    })
    .catch((err) => {
      console.log(err)
    })
}

//update etacher

export const updateTeacher = (id, updatedData) => {
  const { token } = isAuthenticated()

  const jsonObject = JSON.parse(token)
  const access_token = jsonObject.access_token
  return fetch(`${API}/instructor/${id}`, {
    method: 'PATCH', // or 'PATCH' depending on your API
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${access_token}`,
    },
    body: JSON.stringify(updatedData),
  })
    .then((response) => {
      return response.json()
    })
    .catch((err) => {
      console.log(err)
    })
}

//delete TEcheer
export const deleteTeacher = (id) => {
  const { token } = isAuthenticated()

  const jsonObject = JSON.parse(token)
  const access_token = jsonObject.access_token
  return fetch(`${API}/instructor/${id}`, {
    method: 'DELETE',
    headers: {
      Authorization: `Bearer ${access_token}`,
    },
  })
    .then((response) => {
      return response.json()
    })
    .catch((err) => {
      console.log(err)
    })
}
