import React, { useState, useEffect } from 'react'
import { useNavigate, useParams } from 'react-router-dom' // Import useParams

import { Box, Typography, useTheme, Button, Alert } from '@mui/material'

import { DataGrid } from '@mui/x-data-grid'
import { tokens } from '../../theme'

import Header from '../../components/Header'
import CustomToolBar from '../../components/CustomToolBar'

import { deleteStudent, getStudent } from '../../actions/apiStudentsManagement'

const StudentProfile = () => {
  const theme = useTheme()
  const colors = tokens(theme.palette.mode)

  const navigate = useNavigate()
  const [values, setValues] = useState({
    error: '',
    loading: false,
    success: false,
  })

  const { error, loading, success } = values

  const { id: studentId } = useParams() // Get the student ID from the URL params

  const [student, setStudent] = useState(null) // State to store the student's details
  const [courses, setCourses] = useState([]) // State to store the student's courses

  //==============================toast =============================//
  const [open, setOpen] = React.useState(false)
  const [isDeleted, setIsDeleted] = useState(false)
  const [isLoading, setIsLoading] = useState(false)

  const handleClick = () => {
    setOpen(true)
  }

  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return
    }

    setOpen(false)
  }

  //==============================delete student=====================//
  const destroyStudent = (id) => {
    setIsLoading(true)
    deleteStudent(id).then((data) => {
      if (data.error) {
        setValues({
          error: data.error,
          loading: false,
          success: false,
        })
      } else {
        setValues({
          loading: false,
          success: true,
        })
        setIsDeleted(true) // Mark the student as deleted
        handleClick() // Trigger the toast appearance
        navigate('/student/list')
      }
    })
  }
  //==============================Function to load the student's details=====================//

  const loadStudentDetails = () => {
    getStudent(studentId).then((data) => {
      if (data.error) {
        console.log(data.error)
      } else {
        setStudent(data)
      }
    })
  }
  //==============================Function to load the student's  pruchased courses=====================//

  // const loadCourses = () => {
  //
  //   getCoursesForStudent(studentId).then((data) => {
  //     if (data.error) {
  //       console.log(data.error)
  //     } else {
  //       setCourses(data)
  //     }
  //   })
  // }

  //==============================useEffect section=============================================//
  useEffect(() => {
    // Load the student's details and courses when the component mounts
    loadStudentDetails()
    // loadCourses()
  })

  const columns = [
    // New column to display courses
    {
      field: 'courses',
      headerName: 'Courses',
      flex: 2,

      renderCell: () => (
        <Box>
          {courses.map((course) => (
            <Typography key={course.id}>{course.name}</Typography>
          ))}
        </Box>
      ),
    },
  ]

  //================================== Show Error success and loading  method ==========================================//
  //-------------------------------> showError  <-------------------------------------------------------------------
  const showError = () => (
    <div
      className='alert alert-danger'
      style={{ display: error ? '' : 'none' }}
    >
      {error}
    </div>
  )
  //-------------------------------> showSuccess  <-------------------------------------------------------------------
  const showSuccess = () => (
    <div
      className='alert alert-info'
      style={{ display: success ? '' : 'none' }}
    >
      {}
    </div>
  )
  //-------------------------------> showLoading  <-------------------------------------------------------------------
  const showLoading = () =>
    loading && (
      <div className='alert alert-success'>
        <h2>Loading...</h2>
      </div>
    )

  //==============================retunr section=============================================//
  return (
    <Box
      m='20px'
      sx={{
        '& .MuiDataGrid-root': {
          border: 'none',
          fontSize: 16,
        },
        '& .MuiDataGrid-cell': {
          borderBottom: 'none',
        },
        '& .name-column--cell': {
          color: colors.greenAccent[300],
        },
        '& .MuiDataGrid-columnHeaders': {
          backgroundColor: colors.blueAccent[700],
          borderBottom: 'none',
        },
        '& .MuiDataGrid-virtualScroller': {
          backgroundColor: colors.primary[400],
        },
        '& .MuiDataGrid-footerContainer': {
          borderTop: 'none',
          backgroundColor: colors.blueAccent[700],
        },
        '& .MuiCheckbox-root': {
          color: `${colors.greenAccent[200]} !important`,
        },
        '& .MuiDataGrid-toolbarContainer .MuiButton-text': {
          color: `${colors.grey[100]} !important`,
        },
      }}
    >
      {isLoading && showLoading()}
      {showSuccess()}
      {showError()}
      {!isDeleted && student && (
        <Header
          title='Student Profile'
          subtitle={`Profile of ${student.username}`}
        />
      )}

      {!isDeleted && !isLoading && (
        <>
          <Button
            variant='contained'
            color='error'
            onClick={() => destroyStudent(studentId)}
          >
            <Typography color={colors.grey[100]}>Delete Student</Typography>
          </Button>
        </>
      )}

      {!isDeleted ? (
        <Box m='40px 0 0 0' height='75vh'>
          <DataGrid
            rows={[]}
            columns={columns}
            components={{ Toolbar: CustomToolBar }}
          />
        </Box>
      ) : (
        <Alert severity='success'>Student deleted successfully!</Alert>
      )}
    </Box>
  )
}

export default StudentProfile
