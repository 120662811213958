import React, { useState, useEffect } from 'react'
import {
  Box,
  Typography,
  useTheme,
  CircularProgress,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Checkbox,
  FormControlLabel,
} from '@mui/material'
import { DataGrid } from '@mui/x-data-grid'
import Header from '../../components/Header'
import {
  deleteAssignedCourse,
  getAssignedCourses,
} from '../../actions/apiCourseManagement'

import Button from '@mui/material/Button'
import CustomToolBar from '../../components/CustomToolBar'

const AssignedCourses = () => {
  const theme = useTheme()

  const [courses, setCourses] = useState([])
  const [loading, setLoading] = useState(false)
  const [selectedCourse, setSelectedCourse] = useState(null)
  const [openDeleteDialog, setOpenDeleteDialog] = useState(false)
  const [selectedTeacherIds, setSelectedTeacherIds] = useState([])

  useEffect(() => {
    setLoading(true)

    getAssignedCourses()
      .then((data) => {
        // Group courses by title and combine teacher names
        const groupedCourses = {}
        data.forEach((course) => {
          if (!groupedCourses[course.courseName]) {
            groupedCourses[course.courseName] = {
              courseName: course.courseName,
              teachers: [
                { id: course.instructorId, name: course.instructorName },
              ],
              courseId: course.courseId,
              id: course.courseId, // Assuming courseId is unique
            }
          } else {
            groupedCourses[course.courseName].teachers.push({
              id: course.instructorId,
              name: course.instructorName,
            })
          }
        })

        const coursesWithIds = Object.values(groupedCourses)
        setCourses(coursesWithIds)
      })
      .catch((error) => {
        console.error('Error fetching assigned courses:', error)
      })
      .finally(() => {
        setLoading(false)
      })
  }, [])

  const handleDelete = () => {
    if (selectedTeacherIds.length > 0) {
      Promise.all(
        selectedTeacherIds.map((teacherId) =>
          deleteAssignedCourse(teacherId, selectedCourse.courseId)
        )
      )
        .then((responses) => {
          const errors = responses.filter((response) => response.error)
          if (errors.length === 0) {
            // Update state locally without refreshing
            setCourses((prevCourses) => {
              const updatedCourses = prevCourses.map((course) => {
                if (course.courseId === selectedCourse.courseId) {
                  return {
                    ...course,
                    teachers: course.teachers.filter(
                      (teacher) => !selectedTeacherIds.includes(teacher.id)
                    ),
                  }
                }
                return course
              })
              return updatedCourses
            })
            setOpenDeleteDialog(false)
          } else {
            console.error('Error deleting course:', errors[0].error)
          }
        })
        .catch((error) => {
          console.error('Error deleting course:', error)
        })
    } else {
      console.error('Please select at least one teacher to delete.')
    }
  }

  const handleRemoveCourse = (course) => {
    setSelectedCourse(course)
    setOpenDeleteDialog(true)
  }

  const handleCheckboxChange = (teacherId) => {
    if (selectedTeacherIds.includes(teacherId)) {
      setSelectedTeacherIds((prevIds) =>
        prevIds.filter((id) => id !== teacherId)
      )
    } else {
      setSelectedTeacherIds((prevIds) => [...prevIds, teacherId])
    }
  }

  const columns = [
    { field: 'id', headerName: 'ID', flex: 0.1 },
    {
      field: 'courseName',
      headerName: 'Title',
      flex: 1,
      cellClassName: 'name-column--cell',
    },
    {
      field: 'teachers',
      headerName: 'Responsible Teacher(s)',
      flex: 1,
      cellClassName: 'name-column--cell',
      renderCell: ({ row }) => (
        <Typography>
          {row.teachers.map((teacher) => teacher.name).join(', ')}
        </Typography>
      ),
    },
    {
      field: 'delete',
      headerName: 'Remove The Assignment',
      flex: 0.5,
      renderCell: ({ row }) => (
        <Button
          variant='contained'
          color='error'
          onClick={() => handleRemoveCourse(row)}
          style={{ maxWidth: '100%' }}
        >
          <Typography>Remove</Typography>
        </Button>
      ),
    },
  ]

  return (
    <Box m='20px'>
      <Header title='List of courses' />
      <Box
        m='40px 0 0 0'
        height='75vh'
        sx={{
          position: 'relative',
          '& .MuiDataGrid-root': {
            border: 'none',
            fontSize: 16,
          },
          '& .MuiDataGrid-cell': {
            borderBottom: 'none',
          },
          '& .name-column--cell': {
            color: theme.palette.primary.main,
          },
        }}
      >
        {loading ? (
          <CircularProgress
            style={{
              position: 'absolute',
              top: '50%',
              left: '50%',
              transform: 'translate(-50%, -50%)',
            }}
            color='secondary'
          />
        ) : (
          <DataGrid
            rows={courses}
            columns={columns}
            components={{ Toolbar: CustomToolBar }}
          />
        )}
      </Box>
      <Dialog
        open={openDeleteDialog}
        onClose={() => setOpenDeleteDialog(false)}
      >
        <DialogTitle>Select Teacher(s) to Remove</DialogTitle>
        <DialogContent>
          {selectedCourse &&
            selectedCourse.teachers.map((teacher) => (
              <FormControlLabel
                key={teacher.id}
                control={
                  <Checkbox
                    checked={selectedTeacherIds.includes(teacher.id)}
                    onChange={() => handleCheckboxChange(teacher.id)}
                  />
                }
                label={teacher.name}
              />
            ))}
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setOpenDeleteDialog(false)}>Cancel</Button>
          <Button onClick={handleDelete} color='error'>
            Delete
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  )
}

export default AssignedCourses
