import React, { useState } from 'react'
import * as yup from 'yup'
import {
  Box,
  Button,
  TextField,
  Alert,
  Snackbar,
  CircularProgress,
} from '@mui/material'
import useMediaQuery from '@mui/material/useMediaQuery'
import Header from '../../components/Header'
import { createTeacher } from '../../actions/apiTeachersManagement'

//=============================== Add teacher function ===================//
const AddTeacher = () => {
  const isNonMobile = useMediaQuery('(min-width:600px)')

  //=======values for teacher from =========//
  const [values, setValues] = useState({
    username: '',
    password: '',
    errors: {},
    error: '',
    loading: false,
    success: false,
  })

  const { username, password, errors, error, loading } = values

  const [open, setOpen] = useState(false)
  const [openConflict, setOpenConflict] = useState(false)

  //=============================== handle click and close methode for opening and closing  succes toaster and ressting the states to their initials values ===================//

  const handleClick = () => {
    setOpen(true)
    // Clear input fields after the teacher is added successfully
    setValues({
      username: '',
      password: '',
      errors: {},
      error: '',
      loading: false,
      success: true,
    })
  }
  const handleClose = (event, reason) => {
    setOpen(false)
  }

  //=============================== handle click conflict  and close methode for opening and closing  succes toaster and ressting the states to their initials values ===================//

  const handleClickConflict = () => {
    setOpenConflict(true)
  }

  const handleCloseConflict = (event, reason) => {
    setOpenConflict(false)
  }

  //-------------------------------> Handle Change for username and password <------------------------------------------------
  const handleChange = (name) => (event) => {
    const value = event.target.value
    setValues({ ...values, [name]: value, errors: { ...errors, [name]: '' } })
  }

  //-------------------------------> Click Submit handler for username and password <------------------------------------------
  const handleFormSubmit = async (event) => {
    event.preventDefault()

    try {
      // Validate the form using yup schema
      await teacherSchema.validate(values, { abortEarly: false })

      createTeacher(username, password).then((data) => {
        if (data.error) {
          setValues({
            ...values,
            error: data.error,
            loading: false,
            success: false,
            errors: { ...errors, serverError: data.error },
          })

          //----- Check for 409 status code (Conflict) and display a specific toast
          if (data.status === 409) {
            handleClickConflict() //-----ttriggers the conflict toast appearance
          }
        } else {
          setValues({
            ...values,
            loading: false,
            success: true,
          })
          handleClick() //---------trigger the success toast appearance
        }
      })
    } catch (error) {
      // -------If validation fails, update the state with error messages
      const validationErrors = {}
      error.inner.forEach((e) => {
        validationErrors[e.path] = e.message
      })
      setValues({
        ...values,
        errors: { ...errors, ...validationErrors },
      })
    }
  }

  //-------------------------------> showError  <-------------------------------------------------------------------
  const showError = () => (
    <div
      className='alert alert-danger'
      style={{ display: error ? '' : 'none' }}
    >
      {error}
    </div>
  )

  //-------------------------------> showSuccess  <-------------------------------------------------------------------
  const showSuccess = () => (
    <Snackbar
      open={open}
      anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
      autoHideDuration={2000}
      onClose={handleClose}
    >
      <Alert variant='filled' severity='success'>
        Teacher added successfully!
      </Alert>
    </Snackbar>
  )

  //-------------------------------> showConflict  <-------------------------------------------------------------------
  const showConflict = () => (
    <Snackbar
      open={openConflict}
      anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
      autoHideDuration={6000}
      onClose={handleCloseConflict}
    >
      <Alert variant='filled' severity='error'>
        Username already exists. Please choose a different username.
      </Alert>
    </Snackbar>
  )

  //-------------------------------> showLoading  <-------------------------------------------------------------------
  const showLoading = () =>
    loading && (
      <div
        className='alert alert-info'
        style={{
          position: 'fixed',
          top: 0,
          left: 0,
          width: '100%',
          height: '100%',
          backgroundColor: 'rgba(255, 255, 255, 0.8)',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          zIndex: 1000, // Ensure it's on top
        }}
      >
        <CircularProgress />
      </div>
    )
  //=============================== retrun section============ ===================//

  return (
    <Box m='20px'>
      {showLoading()}
      {showSuccess()}
      {showConflict()}
      {showError()}
      <Header title='Create a teacher' />

      <form onSubmit={handleFormSubmit}>
        <Box
          display='grid'
          gap='30px'
          gridTemplateColumns='repeat(4, minmax(0, 1fr))'
          sx={{
            '& > div': { gridColumn: isNonMobile ? undefined : 'span 4' },
          }}
        >
          <TextField
            fullWidth
            variant='filled'
            type='text'
            label='Username'
            onChange={handleChange('username')}
            value={username}
            name='username'
            error={!!errors?.username}
            helperText={errors?.username}
            sx={{ gridColumn: 'span 2' }}
          />
          <TextField
            fullWidth
            variant='filled'
            type='text'
            label='Password'
            onChange={handleChange('password')}
            value={password}
            name='password'
            error={!!errors?.password}
            helperText={errors?.password}
            sx={{ gridColumn: 'span 2' }}
          />
        </Box>
        <Box display='flex' justifyContent='end' mt='20px'>
          <Button type='submit' color='secondary' variant='contained'>
            Create New Teacher
          </Button>
        </Box>
      </form>
    </Box>
  )
}

const teacherSchema = yup.object().shape({
  username: yup.string().required('Username is required'),
  password: yup
    .string()
    .required('Password is required')
    .min(8, 'Password should be more than 8 characters'),
})

export default AddTeacher
