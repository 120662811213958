import React, { useState, useEffect } from 'react'

import {
  Box,
  Typography,
  Divider,
  List,
  ListItem,
  ListItemText,
  Paper,
  Grid,
  Button,
  TextField,
  Radio,
  RadioGroup,
  FormControl,
  FormControlLabel,
  FormLabel,
  InputLabel,
  Select,
  MenuItem,
} from '@mui/material'

import {
  createSemester,
  getSemesters,
  deleteSemester,
} from '../../actions/apiSemestersManagement'

import { getBranches } from '../../actions/apiBranchesManagement'
import { getAllMajors } from '../../actions/apiMajorsManagement'

//=========================  Semester list compnent ===========================================

const SemestersList = () => {
  const [semesters, setSemesters] = useState([])
  const [branches, setBranches] = useState([])
  const [majors, setMajors] = useState([])

  const [semesterForm, setSemesterForm] = useState({
    number: '',
    selectedOption: 'branch', // default to branch
    selectedId: '', // added to store the selected branch or major id
  })

  //=========================  useEffetc   ===========================================

  useEffect(() => {
    fetchSemesters()
    fetchBranches()
    fetchMajors()
  }, [])

  //=========================  get Semesters   ===========================================

  const fetchSemesters = async () => {
    try {
      const response = await getSemesters()
      setSemesters(response)
    } catch (error) {
      console.error('Error fetching semesters:', error)
    }
  }
  //=========================  get branches   ===========================================
  const fetchBranches = async () => {
    try {
      const response = await getBranches()
      setBranches(response)
    } catch (error) {
      console.error('Error fetching branches:', error)
    }
  }
  //=========================  get majoris   ===========================================
  const fetchMajors = async () => {
    try {
      const response = await getAllMajors()
      setMajors(response)
    } catch (error) {
      console.error('Error fetching majors:', error)
    }
  }
  //=========================  Delete Semester   ===========================================
  const handleDeleteSemesterApi = async (id) => {
    const confirmDelete = window.confirm(
      'Are you sure you want to delete this semester?'
    )
    if (confirmDelete) {
      try {
        const response = await deleteSemester(id)
        if (response.error) {
          console.error('Error deleting semester:', response.status)
        } else {
          fetchSemesters()
        }
      } catch (error) {
        console.error('Error deleting semester:', error)
      }
    }
  }

  //=========================  handle semester form change   ===========================================
  const handleSemesterFormChange = (event) => {
    setSemesterForm({
      ...semesterForm,
      [event.target.name]: event.target.value,
    })
  }

  //=========================  add Semesters   ===========================================
  const handleAddSemester = async (event) => {
    event.preventDefault()
    try {
      const { number, selectedOption, selectedId } = semesterForm

      if (!number || !selectedOption || !selectedId) {
        console.error('Please enter all required fields.')
        return
      }

      const response = await createSemester(
        number,
        selectedOption === 'branch' ? selectedId : null,
        selectedOption === 'major' ? selectedId : null
      )

      if (response.error) {
        console.error('Error adding semester:', response.status)
      } else {
        fetchSemesters()
        setSemesterForm({
          number: '',
          selectedOption: 'branch', // reset to branch after submission
          selectedId: '',
        })
      }
    } catch (error) {
      console.error('Error adding semester:', error)
    }
  }

  //=========================  return section   ===========================================
  return (
    <Box m='20px' sx={{ fontSize: '15px' }}>
      <Grid container spacing={5}>
        <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
          <Paper sx={{ padding: '15px' }}>
            <Typography variant='h5' p={2}>
              Add Semester
            </Typography>

            <form onSubmit={handleAddSemester}>
              <TextField
                fullWidth
                variant='filled'
                type='text'
                label='Semester Number'
                onChange={handleSemesterFormChange}
                value={semesterForm.number}
                name='number'
                sx={{ marginBottom: '15px', fontSize: '10rem' }}
              />

              <FormControl component='fieldset' sx={{ marginBottom: '15px' }}>
                <FormLabel component='legend'>Select</FormLabel>
                <RadioGroup
                  row
                  aria-label='select'
                  name='selectedOption'
                  value={semesterForm.selectedOption}
                  onChange={handleSemesterFormChange}
                >
                  <FormControlLabel
                    value='branch'
                    control={<Radio />}
                    label='Branch'
                  />
                  <FormControlLabel
                    value='major'
                    control={<Radio />}
                    label='Major'
                  />
                </RadioGroup>
              </FormControl>

              {semesterForm.selectedOption === 'branch' ? (
                <FormControl fullWidth sx={{ marginBottom: '15px' }}>
                  <InputLabel id='branch-select-label'>Branch</InputLabel>
                  <Select
                    labelId='branch-select-label'
                    id='branch-select'
                    value={semesterForm.selectedId}
                    onChange={handleSemesterFormChange}
                    name='selectedId'
                  >
                    {branches.map((branch) => (
                      <MenuItem key={branch.id} value={branch.id}>
                        {branch.name}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              ) : (
                <FormControl fullWidth sx={{ marginBottom: '15px' }}>
                  <InputLabel id='major-select-label'>Major</InputLabel>
                  <Select
                    labelId='major-select-label'
                    id='major-select'
                    value={semesterForm.selectedId}
                    onChange={handleSemesterFormChange}
                    name='selectedId'
                  >
                    {majors.map((major) => (
                      <MenuItem key={major.id} value={major.id}>
                        {major.name}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              )}

              <Button
                type='submit'
                color='primary'
                variant='contained'
                sx={{ marginBottom: '15px', padding: '10px' }}
              >
                Add Semester
              </Button>
            </form>
          </Paper>
        </Grid>

        <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
          <Paper sx={{ padding: '15px' }}>
            <Typography variant='h5' p={2}>
              Semesters
            </Typography>

            <Divider mb={3} />

            <List>
              {semesters.map((semester, index) => (
                <div key={`semester-${index}`}>
                  <ListItem>
                    <ListItemText primary={`Semester ${semester.number}`} />
                    {semester.majorId && (
                      <Typography>{`Major: ${semester.majorId}`}</Typography>
                    )}
                    {semester.branchId && (
                      <Typography>{`Branch: ${semester.branchId}`}</Typography>
                    )}
                    <Button
                      color='error'
                      variant='contained'
                      onClick={() => handleDeleteSemesterApi(semester.id)}
                      sx={{ marginLeft: '10px' }}
                    >
                      Delete
                    </Button>
                  </ListItem>
                </div>
              ))}
            </List>
          </Paper>
        </Grid>
      </Grid>
    </Box>
  )
}

export default SemestersList
