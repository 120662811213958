import { API } from '../config'
import { isAuthenticated } from '../auth'

export const getAllMajors = () => {
  const { token } = isAuthenticated()
  const jsonObject = JSON.parse(token)
  const access_token = jsonObject.access_token
  console.log('here')
  return fetch(`${API}/majors`, {
    method: 'GET',
    headers: {
      Authorization: `Bearer ${access_token}`,
    },
  })
    .then((response) => response.json())
    .catch((err) => console.log(err))
}
//===================Get majors by branch========================//

export const getMajors = (branchId) => {
  const { token } = isAuthenticated()
  const jsonObject = JSON.parse(token)
  const access_token = jsonObject.access_token
  console.log('here')
  return fetch(`${API}/majors/branch-majors/${branchId}`, {
    method: 'GET',
    headers: {
      Authorization: `Bearer ${access_token}`,
    },
  })
    .then((response) => response.json())
    .catch((err) => console.log(err))
}

//===================Create Major========================//
export const createMajor = (name, branchId) => {
  const { token } = isAuthenticated()
  const jsonObject = JSON.parse(token)
  const access_token = jsonObject.access_token

  const requestBody = JSON.stringify({
    name: name,
    branchId: branchId,
  })

  return fetch(`${API}/majors`, {
    method: 'POST',
    headers: {
      Authorization: `Bearer ${access_token}`,
      'Content-Type': 'application/json',
    },
    body: requestBody,
  })
    .then(async (response) => {
      const data = await response.json()
      if (!response.ok) {
        return { error: true, status: response.status }
      }
      return { data, status: response.status }
    })
    .catch((err) => {
      console.log(err)
      return { error: 'An unexpected error occurred.' }
    })
}

//===================update a  Major========================//

export const updateMajor = (id, name, branchId) => {
  const { token } = isAuthenticated()

  const jsonObject = JSON.parse(token)
  const access_token = jsonObject.access_token

  const requestBody = JSON.stringify({
    name: name,
    branchId: branchId,
  })
  return fetch(`${API}/majors/${id}`, {
    method: 'PATCH', // or 'PATCH' depending on your API
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${access_token}`,
    },
    body: requestBody,
  })
    .then((response) => {
      return response.json()
    })
    .catch((err) => {
      console.log(err)
    })
}

//===================Delete a major ========================//
export const deleteMajor = (id) => {
  const { token } = isAuthenticated()

  const jsonObject = JSON.parse(token)
  const access_token = jsonObject.access_token
  return fetch(`${API}/majors/${id}`, {
    method: 'DELETE',
    headers: {
      Authorization: `Bearer ${access_token}`,
    },
  })
    .then((response) => {
      return response.json()
    })
    .catch((err) => {
      console.log(err)
    })
}
