import { useState, useEffect } from 'react'
import { Routes, Route, useNavigate, useLocation } from 'react-router-dom'
import { CssBaseline, ThemeProvider } from '@mui/material'
import { ColorModeContext, useMode } from './theme'
import Topbar from './scenes/global/Topbar'
import Sidebar from './scenes/global/Sidebar'
import Dashboard from './scenes/dashboard'
import TeachersList from './scenes/lists/TeachersList'
import GradesList from './scenes/lists/GradesList'
import SemestersList from './scenes/lists/SemestersList'
import TeacherProfile from './scenes/profile/TeacherProfile'
import AddTeacher from './scenes/form/AddTeacher'
import Calendar from './scenes/calendar/calendar'
import Login from './scenes/login/Login'
import StudentsList from './scenes/lists/StudentsList'
import AdminRoute from './auth/AdminRoute'
import { isAuthenticated } from './auth'
import AddCourse from './scenes/form/AddCourse'
import CourseDetailsPage from './scenes/form/CourseDetailsPage'
import CoursesList from './scenes/lists/CoursesList'
import StudentProfile from './scenes/profile/StudentProfile'
import AssignCourse from './scenes/form/AssignCourseToTeacher'
import AssignedCourses from './scenes/lists/AssignedCourses'
function App() {
  const [theme, colorMode] = useMode()
  const [isSidebar, setIsSidebar] = useState(true)
  const [authenticationComplete, setAuthenticationComplete] = useState(false)
  const navigate = useNavigate()
  const location = useLocation()

  useEffect(() => {
    const checkAuthentication = async () => {
      const authenticated = await isAuthenticated()
      setAuthenticationComplete(true)

      //------------------- redirects to login if not authenticated and not already on the login page---------------
      if (!authenticated && location.pathname !== '/login') {
        navigate('/login', { replace: true })
      }
    }

    checkAuthentication()
  }, [navigate, location.pathname])

  // If authentication check is not complete, you can render a loading spinner or return null
  if (!authenticationComplete) {
    // You can render a loading spinner or any other loading indicator here
    return null
  }
  //============= iff the path is '/login' than render the login component
  if (location.pathname === '/login') {
    return <Login />
  }

  //=======================return section =========================================
  return (
    <ColorModeContext.Provider value={colorMode}>
      <ThemeProvider theme={theme}>
        <CssBaseline />
        <div className='app'>
          <Sidebar isSidebar={isSidebar} />
          <main className='content'>
            <Topbar setIsSidebar={setIsSidebar} />
            <Routes>
              <Route path='/' element={<AdminRoute component={Dashboard} />} />
              <Route
                path='/teacher/list'
                element={<AdminRoute component={TeachersList} />}
              />
              <Route
                path='/teacher/add'
                element={<AdminRoute component={AddTeacher} />}
              />
              <Route
                path='/teacher/profile/:id'
                element={<AdminRoute component={TeacherProfile} />}
              />
              <Route
                path='/student/list'
                element={<AdminRoute component={StudentsList} />}
              />
              <Route
                path='/student/profile/:id'
                element={<AdminRoute component={StudentProfile} />}
              />
              <Route
                path='/course/add'
                element={<AdminRoute component={AddCourse} />}
              />
              <Route
                path='/course/add/next/:courseId'
                element={<AdminRoute component={CourseDetailsPage} />}
              />
              <Route
                path='/course/list'
                element={<AdminRoute component={CoursesList} />}
              />
              <Route
                path='/course/assign'
                element={<AdminRoute component={AssignCourse} />}
              />
              <Route
                path='/course/assigned'
                element={<AdminRoute component={AssignedCourses} />}
              />
              <Route
                path='/grades/list'
                element={<AdminRoute component={GradesList} />}
              />
              <Route
                path='/semesters/list'
                element={<AdminRoute component={SemestersList} />}
              />
              <Route
                path='/calendar'
                element={<AdminRoute component={Calendar} />}
              />
            </Routes>
          </main>
        </div>
      </ThemeProvider>
    </ColorModeContext.Provider>
  )
}

export default App
