import React, { useState } from 'react'
import { useNavigate } from 'react-router-dom'

import {
  Box,
  Button,
  TextField,
  Alert,
  Snackbar,
  useMediaQuery,
} from '@mui/material'

import { createCourse } from '../../actions/apiCourseManagement'
import Header from '../../components/Header'

//=================================Add cCOurse======================///
const AddCourse = () => {
  const isNonMobile = useMediaQuery('(min-width:600px)')
  const [values, setValues] = useState({
    courseName: '',
    image: null,
    success: false,
  })
  const { courseName, image } = values

  //--------------- Image preview state
  const [imagePreview, setImagePreview] = useState(null)

  //----------------- Snackbar states
  const [open, setOpen] = useState(false)
  const [openConflict, setOpenConflict] = useState(false)

  //---------------- React Router history
  const navigate = useNavigate()

  // Handle Change for form fields
  const handleChange = (name) => (event) => {
    const value = event.target.value
    setValues({ ...values, [name]: value })
  }

  // Handle Image Upload
  const handleImageUpload = (event) => {
    const file = event.target.files[0]

    // Display image preview
    const reader = new FileReader()
    reader.onloadend = () => {
      setImagePreview(reader.result)
    }
    if (file) {
      reader.readAsDataURL(file)
    }

    setValues({ ...values, image: file })
  }

  // Handle Form Submission
  const handleFormSubmit = async (event) => {
    event.preventDefault()
    const formDataCourse = new FormData()
    formDataCourse.append('name', courseName)
    formDataCourse.append('image', image)

    try {
      const data = await createCourse(formDataCourse)

      if (data.error) {
        setValues({
          ...values,
          success: false,
        })

        //----------------------- chekcs if  409 status code (Conflict) and display an error toast , it can be modified tho for every eror
        if (data.status === 409) {
          handleClickConflict()
        }
      } else {
        setValues({
          ...values,
          success: true,
        })
        handleOpenSuccessToaster() //Trigger the success toast appearance

        // Navigate to the next page with the course ID as a parameter
        navigate(`/course/add/next/${data.data.id}`)
      }
    } catch (error) {
      console.log(error)
    }
  }

  // ===================Handle Success Snackbar open and close =====================
  const handleCloseSuccessToaster = (event, reason) => {
    setOpen(false)
  }

  const handleOpenSuccessToaster = () => {
    setOpen(true)
    // Thsi is for clearning the  input fields after the course is added successfully
    setValues({
      courseName: '',
      image: null,
      errors: {},
      error: '',
      success: true,
    })
    setImagePreview(null)
  }

  // =====================Handle Conflict Snackbar open and close=============
  const handleCloseConflict = (event, reason) => {
    setOpenConflict(false)
  }
  const handleClickConflict = () => {
    setOpenConflict(true)
  }

  //=============================================== Render form==============================
  return (
    <Box m='20px'>
      <Snackbar
        open={open}
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
        autoHideDuration={2000}
        onClose={handleCloseSuccessToaster}
      >
        <Alert variant='filled' severity='success'>
          Course added successfully!
        </Alert>
      </Snackbar>
      <Snackbar
        open={openConflict}
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
        autoHideDuration={2000}
        onClose={handleCloseConflict}
      >
        <Alert variant='filled' severity='error'>
          Conflict: Course already exists.
        </Alert>
      </Snackbar>

      {/* --------------------------- Render header----------------------------- */}
      <Header title='Create a Course' />

      {/*------------------------------ Render form -------------------------------*/}
      <form onSubmit={handleFormSubmit}>
        <Box
          display='grid'
          gap='30px'
          gridTemplateColumns='repeat(4, minmax(0, 1fr))'
          sx={{
            '& > div': { gridColumn: isNonMobile ? undefined : 'span 4' },
          }}
        >
          <TextField
            fullWidth
            variant='filled'
            type='text'
            label='Course Name'
            onChange={handleChange('courseName')}
            value={courseName}
            name='courseName'
            sx={{ gridColumn: 'span 4' }}
            required
          />

          <Box
            display='flex'
            flexDirection='column'
            justifyContent='center'
            alignItems='center'
            gridColumn='span 4'
          >
            <input
              type='file'
              accept='image/*'
              onChange={handleImageUpload}
              sx={{ marginTop: 1 }}
              required
            />
            {imagePreview && (
              <Box
                border='1px solid #ccc'
                borderRadius='5px'
                overflow='hidden'
                marginTop='1rem'
                width='400px' // Specify the width of the image preview
                height='250px' // Specify the height of the image preview
              >
                <img
                  src={imagePreview}
                  alt='Course Preview'
                  style={{ width: '100%', height: '100%' }}
                />
              </Box>
            )}
          </Box>
        </Box>

        <Box display='flex' justifyContent='end' mt='20px'>
          <Button type='submit' color='secondary' variant='contained'>
            Next Step
          </Button>
        </Box>
      </form>
    </Box>
  )
}

export default AddCourse
