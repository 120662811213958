//==============================Function to load the teacher's courses=====================//

// const loadCourses = () => {
//   // Use an API function to get the teacher's courses based on the ID
//   // Example: getCoursesForTeacher returns a Promise with the courses
//   // Replace this with your actual API function
//   getCoursesForTeacher(teacherId).then((data) => {
//     if (data.error) {
//       console.log(data.error)
//     } else {
//       setCourses(data)
//     }
//   })
// }

//==============================useEffect section=============================================//
import React, { useState, useEffect } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import {
  Box,
  Typography,
  useTheme,
  Button,
  Alert,
  Snackbar,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  TextField,
} from '@mui/material'

import { DataGrid } from '@mui/x-data-grid'
import { tokens } from '../../theme'
import Header from '../../components/Header'
import CustomToolBar from '../../components/CustomToolBar'
import {
  deleteTeacher,
  getTeacher,
  updateTeacher,
} from '../../actions/apiTeachersManagement'

const TeacherProfile = () => {
  const theme = useTheme()
  const colors = tokens(theme.palette.mode)

  const navigate = useNavigate()
  const [values, setValues] = useState({
    error: '',
    loading: false,
    success: false,
  })

  const { error, loading, success } = values
  const { id: teacherId } = useParams()

  const [teacher, setTeacher] = useState(null)
  const [courses, setCourses] = useState([])

  const [open, setOpen] = useState(false)
  const [isDeleted, setIsDeleted] = useState(false)
  const [isLoading, setIsLoading] = useState(false)
  const [toastOpen, setToastOpen] = React.useState(false)
  const [toastSeverity, setToastSeverity] = React.useState('success') // Default to success
  const [toastMessage, setToastMessage] = React.useState('')

  const [newPassword, setNewPassword] = useState('')

  const handleClick = (message, severity) => {
    setToastMessage(message)
    setToastSeverity(severity)
    setToastOpen(true)
  }

  const loadTeacherDetails = () => {
    getTeacher(teacherId).then((data) => {
      if (data.error) {
        console.log(data.error)
      } else {
        setTeacher(data)
      }
    })
  }

  useEffect(() => {
    loadTeacherDetails()
  })
  const destroyTeacher = (id) => {
    setIsLoading(true)
    deleteTeacher(id).then((data) => {
      if (data.error) {
        setValues({
          error: data.error,
          loading: false,
          success: false,
        })
      } else {
        setValues({
          loading: false,
          success: true,
        })
        setIsDeleted(true)
        handleClick()
        navigate('/teacher/list')
      }
    })
  }
  const columns = [
    {
      field: 'courses',
      headerName: 'Courses',
      flex: 2,
      renderCell: () => (
        <Box>
          {courses.map((course) => (
            <Typography key={course.id}>{course.name}</Typography>
          ))}
        </Box>
      ),
    },
  ]

  const handleUpdatePassword = () => {
    setOpen(true)
  }

  const handleSavePassword = () => {
    updateTeacher(teacherId, { password: newPassword })
      .then((data) => {
        if (data.error) {
          setValues({
            error: data.error,
            loading: false,
            success: false,
          })
          handleClick('Password update failed!', 'error') // Trigger error toaster
        } else {
          setValues({
            loading: false,
            success: true,
          })
          handleClick('Password updated successfully!', 'success') // Trigger success toaster
          setOpen(false) // Close the password dialog
        }
      })
      .catch((error) => {
        console.error(error)
      })
  }

  const showLoading = () =>
    loading && (
      <div className='alert alert-success'>
        <h2>Loading...</h2>
      </div>
    )

  const showSuccess = () => (
    <div
      className='alert alert-info'
      style={{ display: success ? '' : 'none' }}
    ></div>
  )

  const showError = () => (
    <div
      className='alert alert-danger'
      style={{ display: error ? '' : 'none' }}
    >
      {error}
    </div>
  )

  return (
    <Box
      m='20px'
      sx={{
        '& .MuiDataGrid-root': {
          border: 'none',
          fontSize: 16,
        },
        '& .MuiDataGrid-cell': {
          borderBottom: 'none',
        },
        '& .name-column--cell': {
          color: colors.greenAccent[300],
        },
        '& .MuiDataGrid-columnHeaders': {
          backgroundColor: colors.blueAccent[700],
          borderBottom: 'none',
        },
        '& .MuiDataGrid-virtualScroller': {
          backgroundColor: colors.primary[400],
        },
        '& .MuiDataGrid-footerContainer': {
          borderTop: 'none',
          backgroundColor: colors.blueAccent[700],
        },
        '& .MuiCheckbox-root': {
          color: `${colors.greenAccent[200]} !important`,
        },
        '& .MuiDataGrid-toolbarContainer .MuiButton-text': {
          color: `${colors.grey[100]} !important`,
        },
      }}
    >
      {isLoading && showLoading()}
      {showSuccess()}
      {showError()}
      {!isDeleted && teacher && (
        <Header
          title='Teacher Profile'
          subtitle={`Profile of ${teacher.username}`}
        />
      )}

      {!isDeleted && !isLoading && (
        <>
          <Button
            sx={{ mr: 5 }}
            variant='outlined'
            color='secondary'
            onClick={handleUpdatePassword}
          >
            <Typography color={colors.grey[100]}>Update Password</Typography>
          </Button>

          <Button
            variant='outlined'
            color='error'
            onClick={() => destroyTeacher(teacherId)}
          >
            <Typography color={colors.grey[100]}>Delete Teacher</Typography>
          </Button>
        </>
      )}

      {!isDeleted ? (
        <Box m='40px 0 0 0' height='75vh'>
          <DataGrid
            rows={[]}
            columns={columns}
            components={{ Toolbar: CustomToolBar }}
          />
        </Box>
      ) : (
        <Alert severity='success'>Teacher deleted successfully!</Alert>
      )}

      {/* Password dialog */}
      <Dialog open={open} onClose={() => setOpen(false)}>
        <DialogTitle>Update Password</DialogTitle>
        <DialogContent>
          <TextField
            label='New Password'
            type='password'
            fullWidth
            value={newPassword}
            onChange={(e) => setNewPassword(e.target.value)}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setOpen(false)} color='primary'>
            Cancel
          </Button>
          <Button onClick={handleSavePassword} color='primary'>
            Save
          </Button>
        </DialogActions>
      </Dialog>

      {/* Toaster at top right */}
      <Snackbar
        open={toastOpen}
        autoHideDuration={6000}
        onClose={() => setToastOpen(false)}
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
      >
        <Alert onClose={() => setToastOpen(false)} severity={toastSeverity}>
          {toastMessage}
        </Alert>
      </Snackbar>
    </Box>
  )
}

export default TeacherProfile
