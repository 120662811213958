import React from 'react'
import {
  GridToolbarContainer,
  GridToolbarColumnsButton,
  GridToolbarFilterButton,
  GridToolbarExport,
} from '@mui/x-data-grid'

const CustomToolBar = () => {
  return (
    <GridToolbarContainer>
      <GridToolbarColumnsButton sx={{ fontSize: '14px' }} />
      <GridToolbarFilterButton sx={{ fontSize: '14px' }} />
      <GridToolbarExport sx={{ fontSize: '14px' }} />
    </GridToolbarContainer>
  )
}

export default CustomToolBar
