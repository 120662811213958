import React, { useState, useEffect } from 'react'
import { useNavigate } from 'react-router-dom'

import { Box, Typography, useTheme, CircularProgress } from '@mui/material'
import { DataGrid } from '@mui/x-data-grid'
import { tokens } from '../../theme'
import Header from '../../components/Header'

import { deleteCourse, getCourses } from '../../actions/apiCourseManagement'

import Button from '@mui/material/Button'

import CustomToolBar from '../../components/CustomToolBar'

//=========================  Courses List logic ===========================================
const CoursesList = () => {
  const theme = useTheme()
  const colors = tokens(theme.palette.mode)
  const navigate = useNavigate()

  const [courses, setCourses] = useState([])
  const [loading, setLoading] = useState(false)

  //=========================  useEffect  ===========================================
  useEffect(() => {
    setLoading(true)

    getCourses()
      .then((data) => {
        if (data.error) {
          console.log(data.error)
        } else {
          setCourses(data)
        }
      })
      .finally(() => {
        setLoading(false)
      })
  }, [])

  //=========================  Delete COurse  ===========================================
  const destroyCourse = (id) => {
    deleteCourse(id).then((data) => {
      if (data.error) {
      } else {
        setCourses((prevCourses) =>
          prevCourses.filter((course) => course.id !== id)
        )
      }
    })
  }

  //=========================  Redirect to the profile   ===========================================
  const redirectToProfile = (courseId) => {
    // Redirect to the course's profile page
    navigate(`/course/profile/${courseId}`)
  }

  //===============================Columns =====================================
  const columns = [
    { field: 'id', headerName: 'ID', flex: 0.1 },
    {
      field: 'name',
      headerName: 'Title',
      flex: 1,
      cellClassName: 'name-column--cell',
    },

    {
      field: 'profile',
      headerName: 'Details',
      flex: 0.5,
      renderCell: ({ row }) => (
        <Button
          variant='outlined'
          color='secondary'
          onClick={() => redirectToProfile(row.id)}
        >
          <Typography color={colors.grey[100]}>View details</Typography>
        </Button>
      ),
    },
    {
      field: 'delete',
      headerName: 'Delete course',
      flex: 0.5,
      renderCell: ({ row }) => (
        <Button
          variant='contained'
          color='error'
          onClick={() => destroyCourse(row.id)}
          style={{ maxWidth: '100%' }}
        >
          <Typography>Delete</Typography>
        </Button>
      ),
    },
  ]

  return (
    <Box m='20px'>
      <Header title='List of courses' />
      <Box
        m='40px 0 0 0'
        height='75vh'
        sx={{
          position: 'relative',
          '& .MuiDataGrid-root': {
            border: 'none',
            fontSize: 16,
          },
          '& .MuiDataGrid-cell': {
            borderBottom: 'none',
          },
          '& .name-column--cell': {
            color: colors.greenAccent[300],
          },
          '& .MuiDataGrid-columnHeaders': {
            backgroundColor: colors.blueAccent[700],
            borderBottom: 'none',
          },
          '& .MuiDataGrid-virtualScroller': {
            backgroundColor: colors.primary[400],
          },
          '& .MuiDataGrid-footerContainer': {
            borderTop: 'none',
            backgroundColor: colors.blueAccent[700],
          },
          '& .MuiCheckbox-root': {
            color: `${colors.greenAccent[200]} !important`,
          },
          '& .MuiDataGrid-toolbarContainer .MuiButton-text': {
            color: `${colors.grey[100]} !important`,
          },
        }}
      >
        {loading && ( // Show loading indicator while data is being fetched
          <CircularProgress
            style={{
              position: 'absolute',
              top: '50%',
              left: '50%',
              transform: 'translate(-50%, -50%)',
            }}
            color='secondary'
          />
        )}
        {!loading && ( // Show grid once data is loaded
          <DataGrid
            rows={courses}
            columns={columns}
            components={{ Toolbar: CustomToolBar }}
          />
        )}
      </Box>
    </Box>
  )
}

export default CoursesList
