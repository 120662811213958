import React, { useState, useEffect } from 'react'
import { useNavigate } from 'react-router-dom'

import {
  Box,
  Typography,
  Divider,
  List,
  ListItem,
  ListItemText,
  Paper,
  Grid,
  Button,
  TextField,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
} from '@mui/material'
import {
  getAllMajors,
  createMajor,
  updateMajor,
  deleteMajor,
} from '../../actions/apiMajorsManagement'

import {
  getBranches,
  createBranch,
  updateBranch,
  deleteBranch,
} from '../../actions/apiBranchesManagement'

import { getGrades } from '../../actions/apiGradesManagement'

//=========================  Grades List component  ===========================================
const GradesList = () => {
  const [grades, setGrades] = useState([])
  const [branches, setBranches] = useState([])
  const [majors, setMajors] = useState([])
  const navigate = useNavigate()

  const [branchForm, setBranchForm] = useState({
    name: '',
    grade: '',
  })

  const [majorForm, setMajorForm] = useState({
    name: '',
    branch: '',
  })

  //=========================  useEffect  ===========================================
  useEffect(() => {
    fetchGrades()
    fetchBranches()
    fetchMajors()
  }, [])

  //=========================  get grades  ===========================================
  const fetchGrades = async () => {
    try {
      const response = await getGrades()
      setGrades(response)
    } catch (error) {
      console.error('Error fetching grades:', error)
    }
  }
  //=========================  get Branches  ===========================================
  const fetchBranches = async () => {
    try {
      const response = await getBranches()
      setBranches(response)
    } catch (error) {
      console.error('Error fetching branches:', error)
    }
  }

  //=========================  get MAJORS  ===========================================
  // const fetchMajors = async () => {
  //   try {
  //     const branchesResponse = await getBranches()
  //     const majorsPromises = branchesResponse.map((branch) =>
  //       getMajors(branch.id)
  //     )
  //     const majorsResponses = await Promise.all(majorsPromises)

  //     //----- Flatten the array of arrays
  //     const allMajors = majorsResponses.flat()
  //     setMajors(allMajors)
  //   } catch (error) {
  //     console.error('Error fetching majors:', error)
  //   }
  // }
  const fetchMajors = async () => {
    try {
      const response = await getAllMajors()
      setMajors(response)
    } catch (error) {
      console.error('Error fetching majors:', error)
    }
  }

  //=========================  handle branche change PopUp ===========================================
  const handleUpdateBranch = (branch) => {
    const updatedName = prompt(`Update branch name:`, branch.name)
    if (updatedName !== null) {
      handleUpdateBranchApi({ ...branch, name: updatedName })
    }
  }
  //=========================  handle  major chnage  PopUp  ===========================================
  const handleUpdateMajor = (major) => {
    const updatedName = prompt(`Update major name:`, major.name)
    if (updatedName !== null) {
      handleUpdateMajorApi({ ...major, name: updatedName })
    }
  }

  //=========================  add Branch   ===========================================
  const handleAddBranch = async (event) => {
    event.preventDefault()
    try {
      const response = await createBranch(branchForm.name, branchForm.grade)
      if (response.error) {
        console.error(`Error adding branch:`, response.status)
      } else {
        fetchBranches()
        setBranchForm({
          name: '',
          grade: '',
        })
        // await createSemester(1, response.data.id, null)
        // await createSemester(2, response.data.id, null)
      }
    } catch (error) {
      console.error(`Error adding branch:`, error)
    }
  }

  //=========================  add major   ===========================================
  const handleAddMajor = async (event) => {
    event.preventDefault()
    try {
      const response = await createMajor(majorForm.name, majorForm.branch)
      if (response.error) {
        console.error(`Error adding major:`, response.status)
      } else {
        setMajorForm({
          name: '',
          branch: '',
        })
        fetchMajors()
        // await createSemester(1, null, response.data.id)
        // await createSemester(2, null, response.data.id)
      }
    } catch (error) {
      console.error(`Error adding major:`, error)
    }
  }

  //=========================  update The branche   ===========================================
  const handleUpdateBranchApi = async (branch) => {
    try {
      const response = await updateBranch(
        branch.id,
        branch.name,
        branch.gradeCode
      )
      if (response.error) {
        console.error(`Error updating branch:`, response.status)
      } else {
        fetchBranches()
      }
    } catch (error) {
      console.error(`Error updating branch:`, error)
    }
  }
  //=========================  update The branche   ===========================================
  const handleUpdateMajorApi = async (major) => {
    try {
      const response = await updateMajor(major.id, major.name, major.branchId)
      if (response.error) {
        console.error(`Error updating major:`, response.status)
      } else {
        fetchMajors()
      }
    } catch (error) {
      console.error(`Error updating major:`, error)
    }
  }
  //=========================  Delete branch   ===========================================
  const handleDeleteBranchApi = async (id) => {
    const confirmDelete = window.confirm(
      `Are you sure you want to delete this branch?`
    )
    if (confirmDelete) {
      try {
        const response = await deleteBranch(id)

        if (response.error) {
          console.error(`Error deleting branch:`, response.status)
        } else {
          // Fetch branches after deletion
          fetchBranches()

          // Fetch branches after deletion
          fetchMajors()

          // Reset query parameters
          navigate('/grades/list')
        }
      } catch (error) {
        console.error(`Error deleting branch:`, error)
      }
    }
  }
  //=========================  Delete major   ===========================================
  const handleDeleteMajorApi = async (id) => {
    const confirmDelete = window.confirm(
      `Are you sure you want to delete this major?`
    )
    if (confirmDelete) {
      try {
        const response = await deleteMajor(id)
        if (response.error) {
          console.error(`Error deleting major:`, response.status)
        } else {
          fetchMajors()
        }
      } catch (error) {
        console.error(`Error deleting major:`, error)
      }
    }
  }

  //=========================  Handle Branch from change   ===========================================
  const handleBranchFormChange = (event) => {
    setBranchForm({ ...branchForm, [event.target.name]: event.target.value })
  }

  const handleMajorFormChange = (event) => {
    setMajorForm({ ...majorForm, [event.target.name]: event.target.value })
  }

  //=========================  return section   ===========================================

  return (
    <Box m='20px' sx={{ fontSize: '15px' }}>
      <Grid container spacing={5}>
        <Grid item xs={16} sm={14} md={10} lg={6} xl={5}>
          <Paper sx={{ padding: '15px' }}>
            <Typography variant='h5' p={2}>
              Branches
            </Typography>

            <form onSubmit={handleAddBranch}>
              <TextField
                fullWidth
                variant='filled'
                type='text'
                label='Branch name'
                onChange={handleBranchFormChange}
                value={branchForm.name}
                name='name'
                sx={{ marginBottom: '15px', fontSize: '10rem' }}
              />
              <FormControl
                fullWidth
                variant='filled'
                sx={{ marginBottom: '15px' }}
              >
                <InputLabel id='grade-select-label'>Grade</InputLabel>
                <Select
                  fullWidth
                  labelId='grade-select-label'
                  id='grade-select'
                  value={branchForm.grade}
                  onChange={handleBranchFormChange}
                  name='grade'
                >
                  {grades.map((grade) => (
                    <MenuItem key={grade.code} value={grade.code}>
                      {grade.name}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
              <Button
                type='submit'
                color='primary'
                variant='contained'
                sx={{ marginBottom: '15px', padding: '10px' }}
              >
                Add Branch
              </Button>
            </form>
            <Divider mb={3} />
            <List>
              {branches.map((branch, index) => (
                <div key={`branch-${index}`}>
                  <ListItem>
                    <ListItemText primary={branch.name} />
                    <Typography>{`Grade: ${branch.gradeCode}`}</Typography>

                    <Button
                      color='secondary'
                      variant='contained'
                      onClick={() => handleUpdateBranch(branch)}
                      sx={{ margin: '15px' }}
                    >
                      Update
                    </Button>
                    <Button
                      color='error'
                      variant='contained'
                      onClick={() => handleDeleteBranchApi(branch.id)}
                    >
                      Delete
                    </Button>
                  </ListItem>
                </div>
              ))}
            </List>
          </Paper>
        </Grid>

        <Grid item xs={16} sm={14} md={10} lg={6} xl={5}>
          <Paper sx={{ padding: '15px' }}>
            <Typography variant='h5' p={2}>
              Majors
            </Typography>

            <form onSubmit={handleAddMajor}>
              <TextField
                fullWidth
                variant='filled'
                type='text'
                label='Major Name'
                onChange={handleMajorFormChange}
                value={majorForm.name}
                name='name'
                sx={{ marginBottom: '15px' }}
              />
              <FormControl
                fullWidth
                variant='filled'
                sx={{ marginBottom: '15px' }}
              >
                <InputLabel id='branch-select-label'>Branch</InputLabel>
                <Select
                  fullWidth
                  labelId='branch-select-label'
                  id='branch-select'
                  value={majorForm.branch}
                  onChange={handleMajorFormChange}
                  name='branch'
                >
                  {branches.map((branch) => (
                    <MenuItem key={branch.id} value={branch.id}>
                      {branch.name}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
              <Button
                type='submit'
                color='primary'
                variant='contained'
                sx={{ marginBottom: '15px' }}
              >
                Add Major
              </Button>
            </form>
            <Divider mb={3} />
            <List>
              {majors.map((major, index) => (
                <div key={`major-${index}`}>
                  <ListItem>
                    <ListItemText primary={major.name} />
                    <Typography variant='subtitle3'>{`Branch: ${major.branchId}`}</Typography>
                    <Button
                      color='secondary'
                      variant='contained'
                      onClick={() => handleUpdateMajor(major)}
                      sx={{ margin: '15px' }}
                    >
                      Update
                    </Button>
                    <Button
                      color='error'
                      variant='contained'
                      onClick={() => handleDeleteMajorApi(major.id)}
                    >
                      Delete
                    </Button>
                  </ListItem>
                </div>
              ))}
            </List>
          </Paper>
        </Grid>
      </Grid>
    </Box>
  )
}

export default GradesList
