import React, { useState, useEffect } from 'react'

import {
  Box,
  useTheme,
  Card,
  CardContent,
  Typography,
  CircularProgress,
} from '@mui/material'

import BookmarkAddedIcon from '@mui/icons-material/BookmarkAdded'
import BookmarkRemoveIcon from '@mui/icons-material/BookmarkRemove'
import BookmarksIcon from '@mui/icons-material/Bookmarks'
import PeopleIcon from '@mui/icons-material/People'
import SchoolIcon from '@mui/icons-material/School'

import { tokens } from '../../theme'

import Header from '../../components/Header'

import { getStudents } from '../../actions/apiStudentsManagement'
import { getTeachers } from '../../actions/apiTeachersManagement'
import { getCourses } from '../../actions/apiCourseManagement'

const Dashboard = () => {
  const theme = useTheme()
  const colors = tokens(theme.palette.mode)

  const [totalCourses, setTotalCourses] = useState(null)
  const [totalStudents, setTotalStudents] = useState(null)
  const [totalTeachers, setTotalTeachers] = useState(null)

  useEffect(() => {
    Promise.all([getCourses(), getStudents(), getTeachers()])
      .then(([courses, students, teachers]) => {
        setTotalCourses(courses.length)
        setTotalStudents(students.length)
        setTotalTeachers(teachers.length)
      })
      .catch((error) => {
        console.error('Error fetching data:', error)
      })
  }, [])

  return (
    <Box m='20px'>
      {/* Header */}
      <Box display='flex' justifyContent='space-between' alignItems='center'>
        <Header title='DASHBOARD' subtitle='Welcome to your dashboard' />
      </Box>

      {/* Grid and charts */}
      <Box
        display='grid'
        gridTemplateColumns={{ xs: '1fr', md: 'repeat(12, 1fr)' }}
        gap='10px'
      >
        {/* Courses Section */}
        <Typography
          variant='h3'
          color='textPrimary'
          gridColumn={{ xs: 'span 1', md: 'span 12' }}
          fontWeight='bold'
        >
          Courses
        </Typography>
        <Card
          sx={{
            gridColumn: { xs: 'span 1', md: 'span 3' },
            backgroundColor: colors.primary[400],
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            padding: '20px',
          }}
        >
          <BookmarksIcon
            sx={{ color: colors.greenAccent[600], fontSize: '48px' }}
          />
          <CardContent>
            {totalCourses === null ? (
              <CircularProgress
                style={{
                  gridColumn: { xs: 'span 3', md: 'span 1' },
                  color: colors.greenAccent[600],
                }}
              />
            ) : (
              <>
                <Typography variant='h3' fontWeight='bold'>
                  {totalCourses}
                </Typography>
                <Typography variant='h5' color='textSecondary'>
                  Total Courses
                </Typography>
              </>
            )}
          </CardContent>
        </Card>

        <Card
          sx={{
            gridColumn: { xs: 'span 1', md: 'span 3' },
            backgroundColor: colors.primary[400],
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            padding: '20px',
          }}
        >
          <BookmarkAddedIcon
            sx={{ color: colors.greenAccent[600], fontSize: '48px' }}
          />
          <CardContent>
            <Typography variant='h3' fontWeight='bold'>
              Not defined yet
            </Typography>
            <Typography variant='h5' color='textSecondary'>
              Total Approved Courses
            </Typography>
          </CardContent>
        </Card>

        <Card
          sx={{
            gridColumn: { xs: 'span 1', md: 'span 3' },
            backgroundColor: colors.primary[400],
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            padding: '20px',
          }}
        >
          <BookmarkRemoveIcon
            sx={{ color: colors.greenAccent[600], fontSize: '48px' }}
          />
          <CardContent>
            <Typography variant='h3' fontWeight='bold'>
              Not defined yet
            </Typography>
            <Typography variant='h5' color='textSecondary'>
              Total Disapproved Courses
            </Typography>
          </CardContent>
        </Card>

        {/* Instructors Section */}
        <Typography
          variant='h3'
          color='textPrimary'
          gridColumn={{ xs: 'span 1', md: 'span 12' }}
          fontWeight='bold'
        >
          Instructors
        </Typography>
        <Card
          sx={{
            gridColumn: { xs: 'span 1', md: 'span 3' },
            backgroundColor: colors.primary[400],
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            padding: '20px',
          }}
        >
          <PeopleIcon
            sx={{ color: colors.greenAccent[600], fontSize: '48px' }}
          />
          <CardContent>
            {totalTeachers === null ? (
              <CircularProgress
                style={{
                  gridColumn: { xs: 'span 3', md: 'span 1' },
                  color: colors.greenAccent[600],
                }}
              />
            ) : (
              <>
                <Typography variant='h3' fontWeight='bold'>
                  {totalTeachers}
                </Typography>
                <Typography variant='h5' color='textSecondary'>
                  Total Instructors
                </Typography>
              </>
            )}
          </CardContent>
        </Card>

        <Card
          sx={{
            gridColumn: { xs: 'span 1', md: 'span 3' },
            backgroundColor: colors.primary[400],
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            padding: '20px',
          }}
        >
          <PeopleIcon
            sx={{ color: colors.greenAccent[600], fontSize: '48px' }}
          />
          <CardContent>
            <Typography variant='h3' fontWeight='bold'>
              Not defined yet
            </Typography>
            <Typography variant='h5' color='textSecondary'>
              Total Instructors that have courses
            </Typography>
          </CardContent>
        </Card>

        {/* Students Section */}
        <Typography
          variant='h3'
          color='textPrimary'
          gridColumn={{ xs: 'span 1', md: 'span 12' }}
          fontWeight='bold'
        >
          Students
        </Typography>
        <Card
          sx={{
            gridColumn: { xs: 'span 1', md: 'span 3' },
            backgroundColor: colors.primary[400],
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            padding: '20px',
          }}
        >
          <PeopleIcon
            sx={{ color: colors.greenAccent[600], fontSize: '48px' }}
          />
          <CardContent>
            {totalStudents === null ? (
              <CircularProgress
                style={{
                  gridColumn: { xs: 'span 3', md: 'span 1' },
                  color: colors.greenAccent[600],
                }}
              />
            ) : (
              <>
                <Typography variant='h3' fontWeight='bold'>
                  {totalStudents}
                </Typography>
                <Typography variant='h5' color='textSecondary'>
                  Total Students
                </Typography>
              </>
            )}
          </CardContent>
        </Card>

        <Card
          sx={{
            gridColumn: { xs: 'span 1', md: 'span 3' },
            backgroundColor: colors.primary[400],
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            padding: '20px',
          }}
        >
          <SchoolIcon
            sx={{ color: colors.greenAccent[600], fontSize: '48px' }}
          />
          <CardContent>
            <Typography variant='h3' fontWeight='bold'>
              Not defined yet
            </Typography>
            <Typography variant='h5' color='textSecondary'>
              Total Students that bought courses
            </Typography>
          </CardContent>
        </Card>
      </Box>
    </Box>
  )
}

export default Dashboard
