import { API } from '../config'
import { isAuthenticated } from '../auth/index'

// Get All Grades
export const getGrades = () => {
  const { token } = isAuthenticated()
  const jsonObject = JSON.parse(token)
  const access_token = jsonObject.access_token

  return fetch(`${API}/grades`, {
    method: 'GET',
    headers: {
      Authorization: `Bearer ${access_token}`,
    },
  })
    .then((response) => response.json())
    .catch((err) => console.log(err))
}
