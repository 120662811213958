import { API } from '../config'
import { isAuthenticated } from '../auth/index'

// Get All Grades
export const getBranches = () => {
  const { token } = isAuthenticated()
  const jsonObject = JSON.parse(token)
  const access_token = jsonObject.access_token

  return fetch(`${API}/branches`, {
    method: 'GET',
    headers: {
      Authorization: `Bearer ${access_token}`,
    },
  })
    .then((response) => response.json())
    .catch((err) => console.log(err))
}

//create A branch
export const createBranch = (name, grade) => {
  const { token } = isAuthenticated()
  const jsonObject = JSON.parse(token)
  const access_token = jsonObject.access_token

  const requestBody = JSON.stringify({
    name: name,
    gradeCode: grade,
  })

  return fetch(`${API}/branches`, {
    method: 'POST',
    headers: {
      Authorization: `Bearer ${access_token}`,
      'Content-Type': 'application/json',
    },
    body: requestBody,
  })
    .then(async (response) => {
      const data = await response.json()
      if (!response.ok) {
        return { error: true, status: response.status }
      }
      return { data, status: response.status }
    })
    .catch((err) => {
      console.log(err)
      return { error: 'An unexpected error occurred.' }
    })
}

//==============update a branch ===========
export const updateBranch = (id, name, gradeCode) => {
  const { token } = isAuthenticated()

  const jsonObject = JSON.parse(token)
  const access_token = jsonObject.access_token

  const requestBody = JSON.stringify({
    name: name,
    gradeCode: gradeCode,
  })
  return fetch(`${API}/branches/${id}`, {
    method: 'PATCH', // or 'PATCH' depending on your API
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${access_token}`,
    },
    body: requestBody,
  })
    .then((response) => {
      return response.json()
    })
    .catch((err) => {
      console.log(err)
    })
}

//===================delete a branch======//
export const deleteBranch = (id) => {
  const { token } = isAuthenticated()

  const jsonObject = JSON.parse(token)
  const access_token = jsonObject.access_token
  return fetch(`${API}/branches/${id}`, {
    method: 'DELETE',
    headers: {
      Authorization: `Bearer ${access_token}`,
    },
  })
    .then((response) => {
      return response.json()
    })
    .catch((err) => {
      console.log(err)
    })
}
