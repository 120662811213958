import React, { useState } from 'react'
import { Navigate } from 'react-router-dom'

import { signin, authenticate, isAuthenticated } from '../../auth'

import Typography from '@mui/material/Typography'
import Container from '@mui/material/Container'
import Box from '@mui/material/Box'
import Avatar from '@mui/material/Avatar'
import Button from '@mui/material/Button'
import TextField from '@mui/material/TextField'
import CircularProgress from '@mui/material/CircularProgress'
import LockOutlinedIcon from '@mui/icons-material/LockOutlined'
import Snackbar from '@mui/material/Snackbar'
import MuiAlert from '@mui/material/Alert'

const Login = () => {
  const [values, setValues] = useState({
    username: '',
    password: '',
    error: '',
    loading: false,
    openSnackbar: false,
  })

  const {
    username,
    password,
    loading,
    error,
    redirectToReferrer,
    openSnackbar,
  } = values
  const user = isAuthenticated()

  const handleChange = (name) => (event) => {
    setValues({ ...values, error: false, [name]: event.target.value })
  }

  const clickSubmit = (event) => {
    event.preventDefault()
    setValues({ ...values, error: false, loading: true })
    signin({ username, password }).then((data) => {
      if (data.error) {
        setValues({
          ...values,
          error: data.error,
          loading: false,
          openSnackbar: true,
        })
      } else {
        authenticate(data, () => {
          setValues({
            ...values,
            redirectToReferrer: true,
          })
        })
      }
    })
  }

  const signInForm = () => (
    <Container component='main' maxWidth='xs'>
      <Box
        sx={{
          marginTop: 8,
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
        }}
      >
        <Avatar sx={{ m: 1, bgcolor: '#70d8bd' }}>
          <LockOutlinedIcon />
        </Avatar>
        <Typography component='h1' variant='h5'>
          Log In
        </Typography>
        <Box
          component='form'
          onSubmit={clickSubmit}
          noValidate
          sx={{
            marginTop: 1,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            minWidth: '100%',
          }}
        >
          <TextField
            margin='normal'
            required
            fullWidth
            id='username'
            name='username'
            autoComplete='username'
            autoFocus
            placeholder='Username'
            onChange={handleChange('username')}
            value={username}
          />
          <TextField
            margin='normal'
            required
            fullWidth
            name='password'
            type='password'
            id='password'
            placeholder='Password'
            autoComplete='current-password'
            onChange={handleChange('password')}
            value={password}
          />
          <Button
            type='submit'
            variant='contained'
            sx={{ backgroundColor: '#70d8bd' }}
          >
            Log In
          </Button>
        </Box>
      </Box>
    </Container>
  )

  const showErrorSnackbar = () => (
    <Snackbar
      open={openSnackbar}
      autoHideDuration={4000}
      onClose={handleCloseSnackbar}
      anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
      q
    >
      <MuiAlert
        elevation={6}
        variant='filled'
        severity='error'
        onClose={handleCloseSnackbar}
      >
        {error || 'Username or password is incorrect'}
        {/* {error === 'Bad Request'
          ? 'Enter a username and password'
          : 'Username or password is incorrect'} */}
      </MuiAlert>
    </Snackbar>
  )

  const showLoading = () =>
    loading && (
      <div
        className='alert alert-info'
        style={{
          position: 'fixed',
          top: 0,
          left: 0,
          width: '100%',
          height: '100%',
          backgroundColor: 'rgba(255, 255, 255, 0.3)',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          zIndex: 1000, // Ensure it's on top
        }}
      >
        <CircularProgress />
      </div>
    )
  const handleCloseSnackbar = () => {
    setValues({ ...values, openSnackbar: false })
  }

  const redirectUser = () => {
    if (redirectToReferrer && user) {
      return <Navigate to='/' />
    }
    if (isAuthenticated()) {
      return <Navigate to='/' />
    }
  }

  return (
    <>
      {showLoading()}
      {showErrorSnackbar()}
      {signInForm()}
      {redirectUser()}
    </>
  )
}

export default Login
