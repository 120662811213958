import { useParams, useNavigate } from 'react-router-dom'
import React, { useEffect, useState } from 'react'

import {
  Alert,
  Box,
  Button,
  Snackbar,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  useMediaQuery,
} from '@mui/material'

import { createCourseNextStep } from '../../actions/apiCourseManagement'
import {
  getAllGrades,
  getBranchesByGrade,
  getMajorsByBranch,
  getSemestersByBranch,
  getSemestersByMajor,
} from '../../actions/apiCourseDetails'
import Header from '../../components/Header'

//==========================NextStep COurses details
const CourseDetailsPage = () => {
  const { courseId } = useParams()
  const navigate = useNavigate()
  const isNonMobile = useMediaQuery('(min-width:600px)')

  const [successMessage, setSuccessMessage] = useState('')

  // States for form fields
  const [values, setValues] = useState({
    courseName: '',
    grade: '',
    branch: '',
    major: '',
    semester: '',
    image: null,
    errors: {},
    error: '',
    success: false,
  })

  const { grade, branch, major, semester, errors, error } = values

  // States for dropdown options
  const [grades, setGrades] = useState([])
  const [branches, setBranches] = useState([])
  const [majors, setMajors] = useState([])
  const [semesters, setSemesters] = useState([])

  // Snackbar states
  const [open, setOpen] = useState(false)

  // Fetch data for dropdown options on component mount
  useEffect(() => {
    const fetchData = async () => {
      try {
        const gradesData = await getAllGrades()
        setGrades(gradesData)
      } catch (error) {
        console.error('Error fetching grades:', error)
      }
    }

    fetchData()
  }, [])

  //=================Get BRANCHES that are in the grades=========================
  const fetchBranchesByGrade = async (gradeCode) => {
    try {
      const branchesData = await getBranchesByGrade(gradeCode)
      setBranches(branchesData)
    } catch (error) {
      console.error('Error fetching branches:', error)
    }
  }

  //=================Get the majors that are in the bracnhes=========================
  const fetchMajorsByBranch = async (branchId) => {
    try {
      const majorsData = await getMajorsByBranch(branchId)
      setMajors(majorsData)
    } catch (error) {
      console.error('Error fetching majors:', error)
    }
  }

  //=================Get semsters  that are in the major=========================
  const fetchSemestersByMajor = async (majorId) => {
    try {
      const semestersData = await getSemestersByMajor(majorId)

      setSemesters(semestersData)
    } catch (error) {
      console.error('Error fetching semesters:', error)
    }
  }

  //=================HadnleCHange=========================
  const handleChange = (name) => async (event) => {
    const value = event.target.value
    //----------------------------1--check if name is grades than get the branches==============
    if (name === 'grade') {
      // Reset branch, major, and semester when changing the grade
      setValues({
        ...values,
        grade: value,
        branch: '',
        major: '',
        semester: '',
        errors: { ...errors, [name]: '' },
      })
      setMajors([])
      setSemesters([])

      // Fetch branches based on the selected grade
      fetchBranchesByGrade(value)
    } else {
      // Update the state as usual for other fields
      setValues({
        ...values,
        [name]: value,
        errors: { ...errors, [name]: '' },
      })

      //----------------------------2--check if in branch input  than get the majors else get semesters based on majors ==============

      if (name === 'branch') {
        // Reset branch, major, and semester when changing the grade
        setValues({
          ...values,
          branch: value,
          major: '',
          semester: '',
          errors: { ...errors, [name]: '' },
        })
        setMajors([])
        setSemesters([])
        // Check if the selected branch has majors
        const selectedBranch = branches.find(
          (branchOption) => branchOption.id === value
        )

        if (
          !selectedBranch ||
          !selectedBranch.majors ||
          selectedBranch.majors.length === 0
        ) {
          // If the branch doesn't have majors, fetch semesters directly
          try {
            const semestersData = await getSemestersByBranch(value)
            setSemesters(semestersData)
          } catch (error) {
            console.error('Error fetching semesters:', error)
          }
        } else {
          // If the branch has majors, fetch majors based on the selected branch
          fetchMajorsByBranch(value)
        }
      }

      //----------------------------3- check if in major input  than get the semester  based on majors ==============
      if (name === 'major') {
        // Reset branch, major, and semester when changing the grade
        setValues({
          ...values,
          major: value,
          semester: '',
          errors: { ...errors, [name]: '' },
        })

        setSemesters([])
        // Fetch semesters when changing the major
        fetchSemestersByMajor(value)
      }
    }
  }

  //====================================== Handle Form Submission
  const handleFormSubmit = async (event) => {
    event.preventDefault()
    const formDataCourse = new FormData()
    formDataCourse.append('semesterId', semester)
    formDataCourse.append('courseId', courseId)

    try {
      //------------------- Call the API to create a course
      const data = await createCourseNextStep(semester, courseId)

      if (data.error) {
        setValues({
          ...values,
          error: data.error,

          success: false,
          errors: { ...errors, serverError: data.error },
        })
      } else {
        setValues({
          ...values,

          success: true,
        })
        handleClick() //-------- Trigger the success toast
      }
    } catch (validationError) {
      // If validation fails, update the state with error messages
      const validationErrors = {}
      validationError.inner.forEach((e) => {
        validationErrors[e.path] = e.message
      })
      setValues({
        ...values,
        errors: { ...errors, ...validationErrors },
      })
    }
  }

  //================================================ Handle Snackbar  open and close clicks ======================

  const handleClick = () => {
    setOpen(true)
    // Clear input fields after the course is added successfully
    setValues({
      grade: '',
      branch: '',
      major: '',
      semester: '',
      errors: {},
      error: '',
      success: true,
    })

    let countdown = 3
    const countdownInterval = setInterval(() => {
      //------------- Display a countdown message in the existing success snackbar
      setSuccessMessage(
        `Course added successfully! Redirecting in ${countdown} seconds...`
      )

      countdown -= 1

      if (countdown === -1) {
        // Redirect the user to the specified URL
        navigate('/course/add')
        clearInterval(countdownInterval) //-------lear the interval once the countdown reaches 0
      }
    }, 1000)
  }

  const handleClose = (event, reason) => {
    setOpen(false)
  }

  //===================================================== Render form========================
  return (
    <Box m='20px'>
      <Snackbar
        open={open}
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
        autoHideDuration={3000} // Adjust the duration as needed to allow time for the countdown
        onClose={handleClose}
      >
        <Alert variant='filled' severity='success'>
          {successMessage}
        </Alert>
      </Snackbar>

      <div
        className='alert alert-danger'
        style={{ display: error ? '' : 'none' }}
      >
        {error}
      </div>

      {/* Render header */}
      <Header title='CREATE COURSE' subtitle='Create a New Course' />

      {/* Render form */}
      <form onSubmit={handleFormSubmit}>
        <Box
          display='grid'
          gap='30px'
          gridTemplateColumns='repeat(4, minmax(0, 1fr))'
          sx={{
            '& > div': { gridColumn: isNonMobile ? undefined : 'span 4' },
          }}
        >
          <FormControl variant='filled' fullWidth sx={{ gridColumn: 'span 2' }}>
            <InputLabel>Grade</InputLabel>
            <Select
              value={grade}
              onChange={handleChange('grade')}
              error={!!errors?.grade}
            >
              {grades.map((gradeOption) => (
                <MenuItem key={gradeOption.code} value={gradeOption.code}>
                  {gradeOption.name}
                </MenuItem>
              ))}
            </Select>
          </FormControl>

          <FormControl variant='filled' fullWidth sx={{ gridColumn: 'span 2' }}>
            <InputLabel>Branch</InputLabel>
            <Select
              value={branch}
              onChange={handleChange('branch')}
              error={!!errors?.branch}
            >
              {branches.map((branchOption) => (
                <MenuItem key={branchOption.name} value={branchOption.id}>
                  {branchOption.name}
                </MenuItem>
              ))}
            </Select>
          </FormControl>

          <FormControl variant='filled' fullWidth sx={{ gridColumn: 'span 2' }}>
            <InputLabel>Major</InputLabel>
            <Select
              value={major}
              onChange={handleChange('major')}
              error={!!errors?.major}
              disabled={!majors || majors.length === 0} // Disable if majors are not available
            >
              {majors.map((majorOption) => (
                <MenuItem key={majorOption.name} value={majorOption.id}>
                  {majorOption.name}
                </MenuItem>
              ))}
            </Select>
          </FormControl>

          <FormControl variant='filled' fullWidth sx={{ gridColumn: 'span 2' }}>
            <InputLabel>Semester</InputLabel>
            <Select
              value={semester}
              onChange={handleChange('semester')}
              error={!!errors?.semester}
            >
              {semesters.map((semesterOption) => (
                <MenuItem key={semesterOption.number} value={semesterOption.id}>
                  {`Semester ${semesterOption.number}`}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Box>

        <Box display='flex' justifyContent='end' mt='20px'>
          <Button type='submit' color='secondary' variant='contained'>
            Create New Course
          </Button>
        </Box>
      </form>
    </Box>
  )
}

export default CourseDetailsPage
